<template>
  <div>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      table-title="Блоги"
      deleteByIdUrl="/blog/delete"
      deleteByIdMethod="post"
      changeItemWithLoaderMethod="post"
      changeItemWithLoaderUrl="/blog/update"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <a href="/blog/createBlog">
            <button class="btn-t btn--borderColor flex-center">Создать новый блог</button>
          </a>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
          </button>
        </template>
        <template v-else-if="props.column.field === 'is_active'">
          <button v-if="props.row['is_active']" class="btn btn-success w-90"
                  @click="props.row['is_active'] = 0; props.changeItemWithLoader(props.row['id'], {is_active: false})">
            ДА
          </button>
          <button v-else class="btn btn-danger w-90"
                  @click="props.row['is_active'] = 1; props.changeItemWithLoader(props.row['id'], {is_active: true})">
            НЕТ
          </button>
        </template>
        <a v-else :href="'/blog/edit/' + props.row['id']" style="cursor: pointer">
          <template v-if="props.column.field === 'img'">
            <img :src="props.row.img.img_path + '?v=' + props.row.img.updated_at" :alt="props.formattedRow['title']"
                 width="199" height="88">
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </a>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import BaseTable from "../../../components/table/baseTable";
import ProductsAdditionalIngredientsTable from "../productAdditionalIngredient/productAdditionalIngredientsTable.vue";

export default {
  name: "productsList",
  components: {
    ProductsAdditionalIngredientsTable,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      selectedId: '',
      columns: [
        {
          label: 'Картинка',
          field: 'img',
          type: 'number',
          width: '20%',
          sortable: false,
        },
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '5%',
          sortable: true,
        },
        {
          label: 'Заголовок',
          field: 'title',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Описание',
          field: 'description',
          width: '20%',
          sortable: true,
        },
        {
          label: 'Slug',
          field: 'slug',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Активность',
          field: 'is_active',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Удалить',
          field: 'delete',
          type: 'button',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        let imgMobile = null;
        let img = null;
        for (let image of elem.images) {
          if (image.key == 'img') {
            img = image;
          }
        }
        data.push({
          id: elem['id'],
          title: elem['title'],
          slug: elem['slug'],
          description: elem['description'],
          images: elem['images'],
          img: img,
          is_active: elem['is_active'],
          created_at: elem['created_at']
        });
      }
      return data;
    },
  },
  computed: {
    wrapperFiltersData() {
      return {
        title: "Блоги",
        filtersUrl: "/blog/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          onlyActive: {
            enable: true,
            filterKey: 'onlyActive',
            upperSpanText: 'Только активные: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'onlyActive',
            column: 3
          },
        },
      }
    },
  }
}
</script>
