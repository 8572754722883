<template>
  <div>
<!--    <modal-update-->
<!--      :update-modal-input-data="updateModalInputData"-->
<!--      :primary-key-value="selectedId"-->
<!--      :title="'Город ' + selectedId"-->
<!--      @onUpdate="updateList"-->
<!--      updateModalUrl="/city/update"-->
<!--      ref="modalUpdateComponent">-->
<!--    </modal-update>-->
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      :table-title="'Города'"
      deleteByIdUrl="/city/delete"
      deleteByIdMethod="post"
      :addModalInputData="addModalInputData"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
          </button>
        </template>
        <div v-else @click="toggleShowModalUpdate(props.row)" style="cursor: pointer">
          <template v-if="props.column.field === 'img_path'">
            <img :src="props.formattedRow[props.column.field]" :alt="props.formattedRow['title']" width="100" height="50">
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "citiesList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props:[
    'user'
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      selectedId: '',
      updateModalInputData: {
        items:{
          title: {
            simpleInputType: 'text',
            upperSpanText: 'Title',
            defaultValue: '',
            selectedKey: 'title',
          },
        }
      },
      addModalInputData: {
        addModalTitle: 'Добавить',
        addModalUrl: '/city/create',
        addModalMethod: 'post',
        items:{
          title: {
            simpleInputType: 'text',
            placeholder: 'title',
            selectedKey: 'title',
          }
        }
      },
      wrapperFiltersData: {
        title: "Города",
        filtersUrl: "/city/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Title',
          field: 'title',
          width: '25%',
          sortable: true,
        },
        {
          label: 'FIAS_ID',
          field: 'fias_id',
          width: '25%',
          sortable: true,
        },
        // {
        //   label: 'Удалить',
        //   field: 'delete',
        //   type: 'button',
        //   width: '20%',
        //   sortable: false,
        // },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          fias_id: elem['fias_id'],
        });
      }
      return data;
    },
    toggleShowModalUpdate(city) {
      if (city) {
        this.selectedId = city.id
        this.updateModalInputData.items.title.defaultValue = city.title
      }

      if (this.$refs.modalUpdateComponent) {
        this.$refs.modalUpdateComponent.toggleShowModalUpdate();
      }
    },
    updateList() {
      this.$root.$emit('update-list')
    }
  },
}
</script>
