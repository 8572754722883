import Vue from 'vue';
import axios from 'axios';

// import 'bootstrap-vue/dist/bootstrap-vue.css';
// import BootstrapVue from 'bootstrap-vue';

import 'vue-good-table/dist/vue-good-table.css';
import '../assets/css/adminTableCustomize.css';
import VueGoodTable from 'vue-good-table';
import Vuex from 'vuex'
import Vueditor from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'
import Quill from 'quill';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import ImageUploader from 'quill-image-uploader';

import UserCard from "./components/users/userCard.vue";
import UsersTable from "./components/users/usersTable.vue";
import Notifications from 'vue-notification';
import Telegram from "./components/telegram/template/telegram.vue"
import TelegramBotList from "./components/telegram/botList.vue"
import TelegramAlertList from "./components/telegram/alertList.vue"
import TelegramUserList from "./components/telegram/userList.vue"
import EditRole from "./components/roleAndPermissions/editRole.vue";
import Permissions from "./components/roleAndPermissions/permissions.vue";
import CategoriesList from "./components/category/categoriesList.vue";
import RestaurantsList from "./components/restaurants/restaurantsList.vue";
import ProductsList from "./components/product/productsList.vue";
import CitiesList from "./components/city/citiesList.vue";
import AdditionalIngredientsGroupsTable
  from "./components/additionalIngredientGroup/additionalIngredientsGroupsTable.vue";
import ProductAdditionalIngredientsTable
  from "./components/productAdditionalIngredient/productAdditionalIngredientsTable.vue";
import ProductIngredientsModalList from "./components/product/productIngredientsModalList.vue";
import StaticPagesList from "./components/staticPage/staticPagesList.vue";
import BannersList from "./components/banner/bannersList.vue";
import BlogList from "./components/blog/blogList.vue";
import CreateBlog from "./components/blog/createBlog.vue";
import EditBlog from "./components/blog/editBlog.vue";
import RestaurantsCredentials from "./components/restaurants/restaurantsCredentials.vue";
import ChronologyList from "./components/chronology/chronologyList.vue";

axios.defaults.baseUrl = '/';

// axios.interceptors.response.use(
//   function (response) {
//     if (response.headers['content-type'] === 'text/html; charset=utf-8') {
//       location.reload();
//     }
//     return response;
//   },
// );

Vue.prototype.$axios = axios;


// your config here
let config = {
  // fontName: [
  //   {val: 'arial black'},
  //   {val: 'times new roman'},
  //   {val: 'Courier New'}
  // ],
  fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
  uploadUrl: '/blog/save/img'
};

Vue.use(Vuex);
Vue.use(Vueditor, config);
Vue.use(Notifications);

// Vue.use(BootstrapVue);
Vue.use(VueGoodTable);

Vue.component('user-card', UserCard);
Vue.component('users-table', UsersTable);
Vue.component('telegram', Telegram);
Vue.component('bot-list', TelegramBotList);
Vue.component('alert-list', TelegramAlertList);
Vue.component('telegram-user-list', TelegramUserList);
Vue.component('permissions-table', Permissions);
Vue.component('edit-role-table', EditRole);
Vue.component('categories-list', CategoriesList);
Vue.component('restaurants-list', RestaurantsList);
Vue.component('restaurants-credentials-list', RestaurantsCredentials);
Vue.component('products-list', ProductsList);
Vue.component('cities-list', CitiesList);
Vue.component('additional-ingredient-groups', AdditionalIngredientsGroupsTable);
Vue.component('product-additional-ingredients', ProductAdditionalIngredientsTable);
Vue.component('product-ingredient-modal-list', ProductIngredientsModalList);
Vue.component('static-pages-list', StaticPagesList);
Vue.component('banners-list', BannersList);
Vue.component('blogs-list', BlogList);
Vue.component('create-blog', CreateBlog);
Vue.component('edit-blog', EditBlog);
Vue.component('chronology-list', ChronologyList);


new Vue({
  el: '#vue-app',
});
//import Permissions from "./components/roleAndPermissions/permissions";
//import EditRole from "./components/roleAndPermissions/editRole";

Quill.register('modules/imageUploader', ImageUploader);

Vue.use(VueQuillEditor, {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("image", file);

          axios.post('/blog/save/img', formData)
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject("Upload failed");
              console.error("Error:", err)
            })
        })
      },
    },
  },
});

