<template>
  <div>
    <instance-input-select
      :key="'restaurants'"
      :index="'restaurants'"
      :object="{
            multiple: false,
            noGroupSelect: true,
            closeOnSelect: true,
            getDataLink: '/restaurant/getList',
            placeholder: 'Выберите заведение',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            selectedKey: 'id',
            column: 1
          }"
      @add-filter-id-arr-single="addFilterId"
      @add-filter-data-single="addFilterSingle"
      @add-filter-ids-arr="addFilterIdsArray"
      @remove-filter-id="removeFilterId"
      @remove-filter-ids-arr="removeFilterIdsArray">
    </instance-input-select>
    <div>
      <product-ingredient-modal-list
        ref="productIngredientsList"
        :product-id="modalIngredientsProductId"
        :restaurant-id="modalIngredientsRestaurantId"
        :title="modalIngredientsTitle"
      ></product-ingredient-modal-list>
      <base-table
        v-if="selected['id']"
        :key="selected['id']"
        :render-list-to-show="listToShow"
        :per-page="50"
        :wrapper-filters-data="wrapperFiltersData"
        :columns="columns"
        table-title="Продукты"
        deleteByIdUrl="/product/delete"
        deleteByIdMethod="post"
        changeItemWithLoaderMethod="post"
        changeItemWithLoaderUrl="/product/update"
        :add-modal-input-data="addModalInputData"
        :update-modal-input-data="updateModalInputData"
      >
        <template v-slot:base-table-actions="props">
          <div class="topping__btns fx col-12">
            <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
          </div>
        </template>
        <template v-slot:base-table-row="props">
          <template v-if="props.column.field === 'delete'">
            <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
            </button>
          </template>
          <template v-else-if="props.column.field === 'is_active'">
            <button v-if="props.row['is_active']" class="btn btn-success w-90"
                    @click="props.changeItemWithLoader(props.row['id'], {is_active: false})">ДА
            </button>
            <button v-else class="btn btn-danger w-90"
                    @click="props.changeItemWithLoader(props.row['id'], {is_active: true})">НЕТ
            </button>
          </template>
          <template v-else-if="props.column.field === 'ingredients'">
            <button class="btn btn-info w-90" @click="toggleShowModalIngredients(props.row)">
              Ингредиенты
            </button>
          </template>
          <template v-else-if="props.column.field === 'frontpad_id'">
            <span :class="props.formattedRow[props.column.field] ? 'text-success' : 'text-danger'">{{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : 'НЕТ' }}</span>
          </template>
          <div v-else @click="toggleShowModalUpdate(props)" style="cursor: pointer">
            <template v-if="props.column.field === 'img_path'">
              <img :src="props.formattedRow[props.column.field] + '?v=' + props.row.updated_at" :alt="props.formattedRow['title']" width="100" height="100">
            </template>
            <template v-else-if="props.column.field === 'restaurant'">
              {{ props.formattedRow[props.column.field]['title'] + ' (' + props.formattedRow[props.column.field]['id'] + ')' }}
            </template>
            <template v-else-if="props.column.field === 'categories'">
              <template v-for="(category) in props.formattedRow['categories']">
                {{ category['title'] + ' (' + category['type']['description'] + ')' }}<br>
              </template>

            </template>
            <template v-else>
              {{ props.formattedRow[props.column.field] }}
            </template>
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import BaseTable from "../../../components/table/baseTable";
import ProductsAdditionalIngredientsTable from "../productAdditionalIngredient/productAdditionalIngredientsTable.vue";
import InstanceInputSelect from "../../../components/table/instanceInputSelect.vue";
import AddRemoveSelectedMixin from "../../../components/mixins/AddRemoveSelectedMixin.vue";

export default {
  name: "productsList",
  components: {
    InstanceInputSelect,
    ProductsAdditionalIngredientsTable,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
    AddRemoveSelectedMixin
  ],
  props:[
    'user'
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      modalIngredientsProductId: null,
      modalIngredientsRestaurantId: null,
      modalIngredientsTitle: null,
      selectedId: '',
      columns: [
        {
          label: '',
          field: 'img_path',
          type: 'number',
          width: '10%',
          sortable: false,
        },
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '5%',
          sortable: true,
        },
        // {
        //   label: 'Ресторан',
        //   field: 'restaurant',
        //   width: '10%',
        //   sortable: true,
        // },
        {
          label: 'Категории',
          field: 'categories',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Title',
          field: 'title',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Состав',
          field: 'subtitle',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Size title',
          field: 'size_title',
          width: '7%',
          sortable: true,
        },
        {
          label: 'Цена',
          field: 'price',
          width: '7%',
          sortable: true,
        },
        {
          label: 'Slug',
          field: 'slug',
          width: '7%',
          sortable: true,
        },
        {
          label: 'Frontpad',
          field: 'frontpad_id',
          width: '7%',
          sortable: true,
        },
        {
          label: 'Активность',
          field: 'is_active',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Доп. ингредиенты',
          field: 'ingredients',
          width: '10%',
          sortable: true,
        },
        // {
        //   label: 'Удалить',
        //   field: 'delete',
        //   type: 'button',
        //   sortable: false,
        // },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          slug: elem['slug'],
          subtitle: elem['subtitle'],
          size_title: elem['size_title'],
          price: elem['price'],
          img_path: elem['img_path'],
          restaurant: elem['restaurant'],
          categories: elem['categories'],
          frontpad_id: elem['additional_param']['frontpad_id'],
          is_active: elem['is_active'],
          additional_param: elem['additional_param'],
          updated_at: elem['updated_at']
        });
      }
      return data;
    },
    toggleShowModalIngredients(elem) {
      this.modalIngredientsProductId = elem['id']
      this.modalIngredientsRestaurantId = elem['restaurant']['id']
      this.modalIngredientsTitle = 'Ингредиенты товара ' + elem['title'] + ' (' + elem['restaurant']['title'] + ')'
      if (this.$refs.productIngredientsList) {
        this.$refs.productIngredientsList.toggleShowModal()
      }
    },
    toggleShowModalUpdate(props) {
      let elem = props.row
      if (elem) {
        this.updateModalInputData.items.title.defaultValue = elem.title
        this.updateModalInputData.items.slug.defaultValue = elem.slug
        this.updateModalInputData.items.subtitle.defaultValue = elem.subtitle
        this.updateModalInputData.items.size_title.defaultValue = elem.size_title
        this.updateModalInputData.items.is_active.defaultValue = elem.is_active
        this.updateModalInputData.items.price.defaultValue = elem.price
        //this.updateModalInputData.items.restaurant.placeholder = 'Ресторан: ' + elem.restaurant['title'] + ' (' + elem.restaurant['id'] + ')'
        this.updateModalInputData.items.categories.defaultValue = elem.categories
        this.updateModalInputData.items.categories.setDefaultInFilters = elem.categories.map(function (e) { return e.id })
        this.updateModalInputData.items.description.defaultValue = elem.additional_param ? elem.additional_param['description'] : null
        this.updateModalInputData.items.calory.defaultValue = elem.additional_param ? elem.additional_param['calory'] : null
        this.updateModalInputData.items.protein.defaultValue = elem.additional_param ? elem.additional_param['protein'] : null
        this.updateModalInputData.items.fat.defaultValue = elem.additional_param ? elem.additional_param['fat'] : null
        this.updateModalInputData.items.carbs.defaultValue = elem.additional_param ? elem.additional_param['carbs'] : null
        this.updateModalInputData.items.seo_keys.defaultValue = elem.additional_param ? elem.additional_param['seo_keys'] : null
        this.updateModalInputData.items.seo_title.defaultValue = elem.additional_param ? elem.additional_param['seo_title'] : null
        this.updateModalInputData.items.seo_description.defaultValue = elem.additional_param ? elem.additional_param['seo_description'] : null
        this.updateModalInputData.items.frontpad_id.defaultValue = elem.additional_param.frontpad_id ? elem.additional_param.frontpad_id : null
      }

      props.toggleShowModalUpdate(props.row);
    },
  },
  computed: {
    wrapperFiltersData() {
      return {
        title: "Продукты",
        filtersUrl: "/product/getList?restaurantId=" + this.selected['id'],
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          // restaurants: {
          //   enable: true,
          //   multiple: false,
          //   getDataLink: '/restaurant/getList',
          //   placeholder: 'Выберите заведение',
          //   specifyKeyForLabel: 'title',
          //   viewLabel: 'titleWithId',
          //   selectedKey: 'restaurantsIds',
          //   column: 1
          // },
          categories: {
            enable: true,
            multiple: true,
            getDataLink: '/category/getList',
            placeholder: 'Выберите категории',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            selectedKey: 'categoriesIds',
            column: 1
          },
          title: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Название',
            selectedKey: 'title',
            column: 2
          },
          onlyDisabled: {
            enable: true,
            filterKey: 'onlyDisabled',
            upperSpanText: 'Только не активные: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'onlyDisabled',
            column: 3
          },
        },
      }
    },
    updateModalInputData() {
      return {
        updateModalTitle: 'Продукт ' + this.selectedId,
        updateModalUrl: '/product/update',
        updateModalMethod: 'post',
        updateModalPrimaryKey: 'id',
        additionalParams: {
          restaurant_id: this.selected['id']
        },
        items:{
          // restaurant: {
          //   multiple: false,
          //   allowEmpty: false,
          //   closeOnSelect: true,
          //   noGroupSelect: true,
          //   getDataLink: '/restaurant/getList',
          //   specifyKeyForLabel: 'title',
          //   upperSpanText: 'Заведение',
          //   viewLabel: 'titleWithId',
          //   selectedKey: 'restaurant_id',
          // },
          categories: {
            multiple: true,
            getDataLink: '/category/getList',
            specifyKeyForLabel: 'title',
            upperSpanText: 'Категории',
            viewLabel: 'titleWithId',
            selectedKey: 'categoriesIds',
          },
          title: {
            simpleInputType: 'text',
            upperSpanText: 'Title',
            defaultValue: '',
            selectedKey: 'title',
          },
          slug: {
            simpleInputType: 'text',
            upperSpanText: 'Slug',
            defaultValue: '',
            selectedKey: 'slug',
          },
          subtitle: {
            simpleInputType: 'text',
            upperSpanText: 'Состав через запятую',
            defaultValue: '',
            selectedKey: 'subtitle',
          },
          size_title: {
            simpleInputType: 'text',
            upperSpanText: 'Size title',
            defaultValue: '',
            selectedKey: 'size_title',
          },
          price: {
            simpleInputType: 'number',
            upperSpanText: 'Цена',
            defaultValue: '',
            selectedKey: 'price',
          },
          is_active: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Активность',
            placeholder: 'disabled',
            defaultValue: '',
            bottomSpanClass: 'slider round',
            selectedKey: 'is_active',
          },
          img: {
            upperSpanText: 'Картинка 1/1',
            simpleInputType: 'file',
            placeholder: 'Img',
            selectedKey: 'img',
          },
          description: {
            simpleInputType: 'textarea',
            upperSpanText: 'Описание',
            defaultValue: '',
            selectedKey: 'description',
          },
          calory: {
            simpleInputType: 'number',
            upperSpanText: 'Каллорийность',
            defaultValue: '',
            selectedKey: 'calory',
          },
          protein: {
            simpleInputType: 'number',
            upperSpanText: 'Белки',
            defaultValue: '',
            selectedKey: 'protein',
          },
          fat: {
            simpleInputType: 'number',
            upperSpanText: 'Жиры',
            defaultValue: '',
            selectedKey: 'fat',
          },
          carbs: {
            simpleInputType: 'number',
            upperSpanText: 'Углеводы',
            defaultValue: '',
            selectedKey: 'carbs',
          },
          frontpad_id: {
            multiple: false,
            allowEmpty: true,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/product/getFrontpadList?restaurant_id=' + this.selected['id'],
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            upperSpanText: 'frontpad id',
            defaultValue: '',
            selectedKey: 'frontpad_id',
          },
          seo_keys: {
            simpleInputType: 'text',
            upperSpanText: 'SEO keys',
            defaultValue: '',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            upperSpanText: 'SEO title',
            defaultValue: '',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            upperSpanText: 'SEO description',
            defaultValue: '',
            selectedKey: 'seo_description',
          },
        }
      }
    },
    addModalInputData() {
      return {
        addModalTitle: 'Добавить',
        addModalUrl: '/product/create',
        addModalMethod: 'post',
        additionalParams: {
          restaurant_id: this.selected['id']
        },
        items:{
          // restaurants: {
          //   multiple: false,
          //   allowEmpty: false,
          //   closeOnSelect: true,
          //   noGroupSelect: true,
          //   getDataLink: '/restaurant/getList',
          //   placeholder: 'Выберите заведение*',
          //   specifyKeyForLabel: 'title',
          //   viewLabel: 'titleWithId',
          //   selectedKey: 'restaurant_id',
          // },
          categories: {
            multiple: true,
            allowEmpty: false,
            closeOnSelect: false,
            noGroupSelect: false,
            getDataLink: '/category/getList',
            specifyKeyForLabel: 'title',
            placeholder: 'Выберите категории',
            viewLabel: 'titleWithId',
            selectedKey: 'categoriesIds',
          },
          title: {
            simpleInputType: 'text',
            placeholder: 'title*',
            selectedKey: 'title',
          },
          slug: {
            simpleInputType: 'text',
            placeholder: 'slug*',
            selectedKey: 'slug',
          },
          subtitle: {
            simpleInputType: 'text',
            placeholder: 'Состав через запятую*',
            selectedKey: 'subtitle',
          },
          size_title: {
            simpleInputType: 'text',
            placeholder: 'Size title*',
            selectedKey: 'size_title',
          },
          price: {
            simpleInputType: 'number',
            placeholder: 'Цена*',
            selectedKey: 'price',
          },
          is_active: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Активность',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'is_active',
          },
          img: {
            upperSpanText: 'Картинка*',
            simpleInputType: 'file',
            placeholder: 'Img',
            selectedKey: 'img',
          },
          description: {
            simpleInputType: 'textarea',
            placeholder: 'Описание',
            selectedKey: 'description',
          },
          calory: {
            simpleInputType: 'number',
            placeholder: 'Каллорийность',
            selectedKey: 'calory',
          },
          protein: {
            simpleInputType: 'number',
            placeholder: 'Белки',
            selectedKey: 'protein',
          },
          fat: {
            simpleInputType: 'number',
            placeholder: 'Жиры',
            selectedKey: 'fat',
          },
          carbs: {
            simpleInputType: 'number',
            placeholder: 'Углеводы',
            selectedKey: 'carbs',
          },
          frontpad_id: {
            multiple: false,
            allowEmpty: true,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/product/getFrontpadList?restaurant_id=' + this.selected['id'],
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            placeholder: 'frontpad_id',
            defaultValue: '',
            selectedKey: 'frontpad_id',
          },
          seo_keys: {
            simpleInputType: 'text',
            placeholder: 'SEO keys',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            placeholder: 'SEO title',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            placeholder: 'SEO description',
            selectedKey: 'seo_description',
          },
        }
      }
    }
  }
}
</script>
