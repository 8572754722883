<template>
  <div>
    <v-notification>
    </v-notification>
    <h1>Создать новый блог</h1>
    <div class="form-group">
      <label for="exampleFormControlInput1">Slug для статьи (на английском языке) *</label>
      <input type="text" class="form-control" v-model="slug">
    </div>
    <div class="form-group mt-5">
      <label class="mb-1"><b>Рекомендуемый размер картинки: 397px на 176px</b></label>
      <div class="mt-0 base-image-input"
           :style="{ 'background-image': `url(${imageData})` }"
           @click="chooseImage">
        <span
          v-if="!imageData"
          class="placeholder"
        >
          Выберите файл картинки для списка блогов
        </span>
      </div>
      <input class="form-control" type="file" id="formFile" ref="file" @input="onSelectFile">
      <div class="form-group">
        <label for="exampleFormControlInput1">Заголовок статьи *</label>
        <input type="text" class="form-control" v-model="blogTitle">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Краткое описание блога *</label>
        <input type="text" class="form-control" v-model="shortBlogDescription">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Сео заголовок</label>
        <input type="text" class="form-control" v-model="seoTitle">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Сео описание</label>
        <input type="text" class="form-control" v-model="seoDescription">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Сео слова</label>
        <input type="text" class="form-control" v-model="seoKeywords">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Время чтения(в минутах, число)</label>
        <input type="number" class="form-control" v-model="readTime">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Тело статьи</label>
        <Vueditor ref="editor" style="height: 500px"/>
      </div>
      <div class="col-3 form__field">
        <label id="password">
          <span class="form__field-span">Сделать активным: </span>
        </label>
      </div>
      <div class="col-9 form__field">
        <label class="switch">
          <input type="checkbox"
                 placeholder="active"
                 v-model="isVisible"
          >
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <button type="button" class="btn btn-success mt-5" @click="createBlog">
      Создать новый блог
    </button>
  </div>
</template>
<script>
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";

export default {
  name: 'creteBlog',
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    Multiselect,
    BaseTable
  },
  data: function () {
    return {
      blogTitle: '',
      slug: '',
      shortBlogDescription: '',
      seoTitle: '',
      seoDescription: '',
      readTime: '',
      seoKeywords: '',
      imageData: null,
      images: [],
      isVisible: 1
    }
  },
  methods: {
    chooseImage() {
      this.$refs.file.click()
    },
    onSelectFile() {
      const input = this.$refs.file
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader
        reader.onload = e => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }
    },
    async createBlog() {
      let file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('img', file);
      formData.append('title', this.blogTitle);
      formData.append('body', this.$refs.editor.getContent());
      formData.append('description', this.shortBlogDescription);
      formData.append('seo_title', this.seoTitle);
      formData.append('read_time', this.readTime);
      formData.append('seo_description', this.seoDescription);
      formData.append('seo_keywords', this.seoKeywords);
      formData.append('is_active', this.isVisible);
      formData.append('slug', this.slug);

      let createBlogResponse = await this.sendRequest('/blog/create', 'post', formData, {'Content-Type': 'multipart/form-data'});
      if (createBlogResponse['status'] == 'ok') {
        this.clearFormData();
        await this.alertMessage(createBlogResponse['message']);
        setTimeout(function () {
          window.location.href = '/blog'
        }, 2000);
      }
    },
    clearFormData() {
      this.selectedTags = []
      this.faqFields = []
      this.blogTitle = ''
      this.slug = ''
      this.shortBlogDescription = ''
      this.seoTitle = ''
      this.readTime = ''
      this.seoDescription = ''
      this.seoKeywords = []
      this.imageData = null
      this.images = []
      this.availableTags = []
    },
  },
}
</script>
<style scoped>
.base-image-input {
  display: block;
  width: 600px;
  height: 400px;
  cursor: pointer;
  background-size: cover;
  margin-top: 10px;
  background-position: center center;
}

.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 13px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #E0E0E0;
}

.file-input {
  display: none;
}
</style>
