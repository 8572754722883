<template>
  <div>
    <v-notification>
    </v-notification>
    <h1>Редактировать статью №{{ blogId }}</h1>
    <div class="form-group">
      <label for="exampleFormControlInput1">Slug для статьи (на английском языке) *</label>
      <input type="text" class="form-control" v-model="slug">
    </div>
    <div class="form-group mt-5">
      <div class="mt-0 base-image-input"
           :style="{ 'background-image': `url(${imageData + '?v=' + imageTimestamp})` }"
           ref="baseImageInput"
           @click="chooseImage">
        <span v-if="!imageData" class="placeholder">
          Выберите файл картинки для списка блогов
        </span>
      </div>
      <input class="form-control" type="file" id="formFile" ref="file" @input="onSelectFile">
      <div class="form-group">
        <label for="exampleFormControlInput1">Заголовок статьи *</label>
        <input type="text" class="form-control" v-model="blogTitle">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Краткое описание блога *</label>
        <input type="text" class="form-control" v-model="shortBlogDescription">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Сео заголовок</label>
        <input type="text" class="form-control" v-model="seoTitle">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Сео описание</label>
        <input type="text" class="form-control" v-model="seoDescription">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Сео слова</label>
        <input type="text" class="form-control" v-model="seoKeywords">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Время чтения(в минутах, число)</label>
        <input type="number" class="form-control" v-model="readTime">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1">Тело статьи</label>
        <Vueditor ref="editor" style="height: 500px"/>
      </div>
      <div class="col-3 form__field">
        <label id="password">
          <span class="form__field-span">Сделать видимым: </span>
        </label>
      </div>
      <div class="col-9 form__field">
        <label class="switch">
          <input type="checkbox"
                 placeholder="active"
                 v-model="isVisible"
          >
          <span class="slider round"></span>
        </label>
      </div>
      <button type="button" class="btn btn-success mt-5" @click="editBlog">
        Сохранить
      </button>
    </div>
    <button type="button" class="btn btn-danger mt-5" @click="deleteBlog">
      Удалить блог
    </button>
  </div>
</template>
<script>
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";

export default {
  name: 'editBlog',
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    Multiselect,
    BaseTable
  },
  data: function () {
    return {
      blogTitle: '',
      slug: '',
      shortBlogDescription: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: [],
      imageData: null,
      imageTimestamp: null,
      images: [],
      readTime: '',
      isVisible: null,
    }
  },
  props: [
    'blogId',
  ],
  mounted() {
    this.getBlog();
  },
  methods: {
    chooseImage() {
      this.$refs.file.click()
    },
    onSelectFile() {
      const input = this.$refs.file
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader
        reader.onload = e => {
          this.imageData = e.target.result
          this.$refs.baseImageInput.style.backgroundImage = `url(${this.imageData})`
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }
    },
    async editBlog() {
      let formData = new FormData();
      formData.append('id', this.blogId);
      formData.append('slug', this.slug);
      formData.append('title', this.blogTitle);
      formData.append('body', this.$refs.editor.getContent());
      formData.append('description', this.shortBlogDescription);
      formData.append('seo_title', this.seoTitle);
      formData.append('seo_description', this.seoDescription);
      formData.append('seo_keywords', this.seoKeywords);
      formData.append('read_time', this.readTime);
      if (this.$refs.file.files[0]) {
        formData.append('img', this.$refs.file.files[0]);
      }
      formData.append('is_active', this.isVisible);
      let updateOrCreateBlogResponse = await this.sendRequest('/blog/update', 'post', formData, {'Content-Type': 'multipart/form-data'});
      if (updateOrCreateBlogResponse['status'] == 'ok') {
        await this.alertMessage('Успешно обновлено');
      }
    },
    async getBlog() {
      let blogResponse = await this.sendRequest('/blog/getById', 'get', {
        id: this.blogId,
      });
      if (blogResponse['status'] == 404) {
        await this.alertMessage(blogResponse['message']);
        setTimeout(function () {
          window.location.href = '/blog'
        }, 2000);
      } else {
        let foundedBlog = blogResponse;
        this.blogTitle = foundedBlog.title;
        this.slug = foundedBlog.slug;
        this.shortBlogDescription = foundedBlog.description;
        this.seoTitle = foundedBlog.seo_title;
        this.seoDescription = foundedBlog.seo_description;
        this.seoKeywords = foundedBlog.seo_keywords;
        this.isVisible = !!foundedBlog.is_active;
        this.readTime = foundedBlog.read_time;
        this.imageData = foundedBlog.images[0].img_path;
        this.imageTimestamp = foundedBlog.images[0].updated_at;
        this.$refs.editor.setContent(foundedBlog.body);
        this.$refs.baseImageInput.style.backgroundImage = `url(${this.imageData})`;
      }
    },
    async deleteBlog() {
      if (confirm('Вы уверены что хотите удалить блог?')) {
        let deleteTranslationResponse = await this.sendRequest('/blog/delete', 'post', {id: this.blogId});
        if (deleteTranslationResponse['status'] == 'ok') {
          await this.alertMessage('Блог удален успешно');
          setTimeout(function () {
            window.location.href = '/blog'
          }, 2000);
        }
      }
    },
  },
}
</script>
<style scoped>
.base-image-input {
  display: block;
  width: 600px;
  height: 400px;
  cursor: pointer;
  background-size: cover;
  margin-top: 10px;
  background-position: center center;
}

.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 13px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #E0E0E0;
}

.file-input {
  display: none;
}
</style>
