<template>
  <div class="edit-role-modal">
    <v-notification>
    </v-notification>
    <h1 class="mb-5">Редактирование роли <b>{{ role }}</b></h1>
    <div class="col-lg-12 col-sm-3">
      <multiselect
        v-model="newPermissions"
        placeholder="Выберите разрешения"
        label="name"
        track-by="id"
        :closeOnSelect="true"
        :group-select="true"
        :showLabels="false"
        :options="wrapperForAllSelected(allPermissions)"
        :multiple="true"
        openDirection="bottom"
      ></multiselect>
    </div>
    <button class="btn btn-success" @click="updateRolePermissions">Обновить роль</button>
  </div>
</template>

<script>
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";

export default {
  name: 'blogList',
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    Multiselect,
    BaseTable
  },
  props:[
    'role',
    'roleHasPermission',
    'allPermissions',
  ],
  mounted() {
    this.newPermissions = this.roleHasPermission;
  },
  data: function () {
    return {
      newPermissions: {}
    }
  },
  methods: {
    updateRolePermissions: async function () {
      let role = this.role;
      let newPermissions = this.newPermissions;
      let response = await this.sendRequest('/permissions/roles/edit','post',{role,newPermissions});
      if (response['status'] == 'ok') {
        await this.alertMessage('Permissions updated');
      }
    },
    wrapperForAllSelected: function (data) {
      return data;
    }
  }
}
</script>
<style>
.edit-role-modal{
  height: 600px;
}
</style>
