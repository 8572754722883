<template>
  <div>
    <modal-update
      :update-modal-input-data="updateModalInputData"
      :primary-key-value="selectedId"
      :title="'Страница ' + selectedId"
      @onUpdate="updateList"
      updateModalUrl="/staticPage/update"
      ref="modalUpdateComponent">
    </modal-update>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      :table-title="'Статические страницы'"
      deleteByIdUrl="/staticPage/delete"
      deleteByIdMethod="post"
      :addModalInputData="addModalInputData"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
          </button>
        </template>
        <div v-else @click="toggleShowModalUpdate(props.row)" style="cursor: pointer">
          <template v-if="props.column.field === 'type'">
            {{
              props.formattedRow[props.column.field]['description'] + ' (' + props.formattedRow[props.column.field]['id'] + ')'
            }}
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "categoriesList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props:[
    'user'
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      selectedId: '',
      updateModalInputData: {
        items:{
          urn: {
            simpleInputType: 'text',
            upperSpanText: 'urn',
            defaultValue: '',
            selectedKey: 'urn',
          },
          seo_keys: {
            simpleInputType: 'text',
            upperSpanText: 'SEO keys',
            defaultValue: '',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            upperSpanText: 'SEO title',
            defaultValue: '',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            upperSpanText: 'SEO description',
            defaultValue: '',
            selectedKey: 'seo_description',
          },
        }
      },
      addModalInputData: {
        addModalTitle: 'Добавить',
        addModalUrl: '/staticPage/create',
        addModalMethod: 'post',
        items:{
          urn: {
            simpleInputType: 'text',
            placeholder: 'urn',
            selectedKey: 'urn',
          },
          seo_keys: {
            simpleInputType: 'text',
            placeholder: 'SEO keys',
            selectedKey: 'seo_keys',
          },
          seo_title: {
            simpleInputType: 'text',
            placeholder: 'SEO title',
            selectedKey: 'seo_title',
          },
          seo_description: {
            simpleInputType: 'textarea',
            placeholder: 'SEO description',
            selectedKey: 'seo_description',
          },
        }
      },
      wrapperFiltersData: {
        title: "Статические страницы",
        filtersUrl: "/staticPage/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
      },
      columns: [
        {
          label: 'URN',
          field: 'urn',
          width: '15%',
          sortable: true,
        },
        {
          label: 'SEO keys',
          field: 'seo_keys',
          width: '10%',
          sortable: false,
        },
        {
          label: 'SEO title',
          field: 'seo_title',
          width: '10%',
          sortable: false,
        },
        {
          label: 'SEO description',
          field: 'seo_description',
          sortable: false,
        },
        {
          label: 'Удалить',
          field: 'delete',
          type: 'button',
          width: '15%',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          urn: elem['urn'],
          seo_keys: elem['seo_keys'],
          seo_title: elem['seo_title'],
          seo_description: elem['seo_description'],
        });
      }
      return data;
    },
    toggleShowModalUpdate(elem) {
      if (elem) {
        this.selectedId = elem.id
        this.updateModalInputData.items.urn.defaultValue = elem.urn
        this.updateModalInputData.items.seo_keys.defaultValue = elem.seo_keys
        this.updateModalInputData.items.seo_title.defaultValue = elem.seo_title
        this.updateModalInputData.items.seo_description.defaultValue = elem.seo_description
      }

      if (this.$refs.modalUpdateComponent) {
        this.$refs.modalUpdateComponent.toggleShowModalUpdate();
      }
    },
    updateList() {
      this.$root.$emit('update-list')
    }
  },
}
</script>
