var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"pagination fx fx-center"},[(_vm.totalPages > 1)?[_c('div',{staticClass:"pagination-select-page dropdown"},[_c('button',{staticClass:"dropdown-toggle",on:{"click":_vm.toggleDropdown}},[_vm._v(_vm._s(_vm.perPageDefault))]),_vm._v(" "),(_vm.isOpen)?_c('ul',{staticClass:"unordered-pagination-list"},_vm._l((_vm.options),function(option){return _c('li',{key:option.value,staticStyle:{"list-style":"none"}},[_c('button',{staticClass:"pagination-option",on:{"click":function($event){return _vm.customPerPerChange(option.value)}}},[_vm._v(_vm._s(option.label))])])}),0):_vm._e()])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"pagination__list fx fx-center"},[_c('div',{staticClass:"fx position-relative pagination-list"},[(_vm.totalPages > 1 && _vm.currentPage !== 1)?_c('div',{staticClass:"pagination__item pagination__item--btn"},[_c('button',{staticClass:"pagination__link pagination_link_prev",on:{"click":function($event){return _vm.customPageChange(_vm.currentPage - 1)}}},[_c('svg',{attrs:{"viewBox":"0 0 14 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.909043 12C0.909043 11.7211 1.01568 11.4418 1.22868 11.2288L12.1378 0.319704C12.564 -0.106568 13.2543 -0.106568 13.6803 0.319704C14.1063 0.745976 14.1066 1.43625 13.6803 1.86225L3.54249 12L13.6803 22.1379C14.1066 22.5641 14.1066 23.2544 13.6803 23.6804C13.254 24.1064 12.5638 24.1067 12.1378 23.6804L1.22868 12.7713C1.01568 12.5583 0.909043 12.279 0.909043 12Z"}})])])]):_vm._e(),_vm._v(" "),_vm._l((_vm.pages),function(page){return [(
                  _vm.totalPages > 1
                  && (
                    ((page + 2) >= _vm.currentPage && page <= _vm.currentPage)
                    || ((page - 2) <= _vm.currentPage && page > _vm.currentPage)
                    || ((_vm.currentPage - 2) <= 0 && page <= 5)
                    || ((_vm.currentPage + 3) >= _vm.totalPages && page >= _vm.totalPages - 6)
                  )
          )?_c('div',{staticClass:"pagination__item"},[_c('button',{class:'pagination__link' + (page === _vm.currentPage ? ' pagination__link--active' : ''),on:{"click":function($event){return _vm.customPageChange(page)}}},[_vm._v(_vm._s(page))])]):_vm._e()]}),_vm._v(" "),(_vm.totalPages - 4 >= _vm.currentPage)?_c('div',{staticClass:"pagination__item"},[_c('button',{staticClass:"pagination__link",on:{"click":function($event){_vm.customPageChange(_vm.currentPage + 3 + ((_vm.currentPage - 3 < 0) ? -(_vm.currentPage - 3) : 0))}}},[_vm._v("...")])]):_vm._e(),_vm._v(" "),(_vm.totalPages - 4 >= _vm.currentPage)?_c('div',{staticClass:"pagination__item"},[_c('button',{staticClass:"pagination__link",on:{"click":function($event){return _vm.customPageChange(_vm.totalPages)}}},[_vm._v(_vm._s(_vm.totalPages))])]):_vm._e(),_vm._v(" "),(_vm.totalPages > 1 && _vm.currentPage !== _vm.totalPages)?_c('div',{staticClass:"pagination__item pagination__item--btn"},[_c('button',{staticClass:"pagination__link pagination_link_next",on:{"click":function($event){return _vm.customPageChange(_vm.currentPage + 1)}}},[_c('svg',{attrs:{"viewBox":"0 0 14 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.091 12C13.091 12.2789 12.9843 12.5582 12.7713 12.7712L1.86225 23.6803C1.43597 24.1066 0.745703 24.1066 0.319704 23.6803C-0.106295 23.254 -0.106568 22.5638 0.319704 22.1378L10.4575 12L0.319704 1.86215C-0.106568 1.43587 -0.106568 0.745602 0.319704 0.319603C0.745976 -0.106398 1.43625 -0.10667 1.86225 0.319603L12.7713 11.2287C12.9843 11.4417 13.091 11.721 13.091 12Z"}})])])]):_vm._e()],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }