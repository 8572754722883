<template>
  <div>
    <instance-input-select
      :key="'restaurants'"
      :index="'restaurants'"
      :object="{
            multiple: false,
            noGroupSelect: true,
            closeOnSelect: true,
            getDataLink: '/restaurant/getList',
            placeholder: 'Выберите заведение',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            selectedKey: 'id',
            column: 1
          }"
      @add-filter-id-arr-single="addFilterId"
      @add-filter-data-single="addFilterSingle"
      @add-filter-ids-arr="addFilterIdsArray"
      @remove-filter-id="removeFilterId"
      @remove-filter-ids-arr="removeFilterIdsArray">
    </instance-input-select>
    <div>
      <base-table
        v-if="selected['id']"
        :key="selected['id']"
        :render-list-to-show="listToShow"
        :per-page="50"
        :wrapper-filters-data="wrapperFiltersData"
        :columns="columns"
        :table-title="tableTitle"
        changeItemWithLoaderMethod="post"
        changeItemWithLoaderUrl="/productAdditionalIngredient/update"
        :add-modal-input-data="addModalInputData"
        :update-modal-input-data="updateModalInputData"
      >
        <template v-slot:base-table-actions="props">
          <div class="topping__btns fx col-12">
            <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
          </div>
        </template>
        <template v-slot:base-table-row="props">
          <template v-if="props.column.field === 'is_active'">
            <button v-if="props.row['is_active']" class="btn btn-success w-90"
                    @click="props.row['is_active'] = 0; props.changeItemWithLoader(props.row['id'], {is_active: false})">
              ДА
            </button>
            <button v-else class="btn btn-danger w-90"
                    @click="props.row['is_active'] = 1; props.changeItemWithLoader(props.row['id'], {is_active: true})">
              НЕТ
            </button>
          </template>
          <div v-else @click="toggleShowModalUpdate(props)" style="cursor: pointer">
            <template v-if="props.column.field === 'restaurant'">
              {{
                props.formattedRow[props.column.field]['title'] + ' (' + props.formattedRow[props.column.field]['id'] + ')'
              }}
            </template>
            <template v-else-if="props.column.field === 'additional_ingredient_group'">
              {{
                props.formattedRow[props.column.field]['title'] + ' (' + props.formattedRow[props.column.field]['group_type']['description'] + ')'
              }}
            </template>
            <template v-else>
              {{ props.formattedRow[props.column.field] }}
            </template>
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";
import InstanceInputSelect from "../../../components/table/instanceInputSelect.vue";
import AddRemoveSelectedMixin from "../../../components/mixins/AddRemoveSelectedMixin.vue";

export default {
  name: "productsAdditionalIngredientsTable",
  components: {
    InstanceInputSelect,
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
    AddRemoveSelectedMixin
  ],
  props: [
    'user'
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '5%',
          sortable: true,
        },
        {
          label: 'Заголовок',
          field: 'title',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Цена',
          field: 'price',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Группа',
          field: 'additional_ingredient_group',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Активен',
          field: 'is_active',
          width: '10%',
          sortable: true,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          price: elem['price'],
          is_active: elem['is_active'],
          restaurant: elem['restaurant'],
          additional_ingredient_group: elem['additional_ingredient_group']
        });
      }
      return data;
    },
    toggleShowModalUpdate(props) {
      let elem = props.row
      if (elem) {
        this.updateModalInputData.items.title.defaultValue = elem.title
        this.updateModalInputData.items.is_active.defaultValue = elem.is_active
        this.updateModalInputData.items.price.defaultValue = elem.price
        this.updateModalInputData.items.additional_ingredient_group.defaultValue = elem.additional_ingredient_group['id']
      }
      props.toggleShowModalUpdate(props.row);
    },
  },
  computed: {
    tableTitle() {
      return 'Ингредиенты ресторана ' + this.selected['id']
    },
    wrapperFiltersData() {
      return {
        filtersUrl: "/productAdditionalIngredient/getList?restaurantId=" + this.selected['id'],
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          additionalIngredientGroups: {
            multiple: true,
            getDataLink: '/additionalIngredientGroup/getList?restaurantsIds[0]=' + this.selected['id'],
            placeholder: 'Выберите группы',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            selectedKey: 'additionalIngredientGroupsIds',
            column: 1
          },
          title: {
            simpleInputType: 'text',
            placeholder: 'Название',
            selectedKey: 'title',
            column: 2
          },
          onlyDisabled: {
            filterKey: 'required',
            upperSpanText: 'Только не активные: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'onlyDisabled',
            column: 3
          },
        },
      }
    },
    updateModalInputData() {
      return {
        updateModalTitle: 'Ингредиент',
        updateModalUrl: '/productAdditionalIngredient/update',
        updateModalMethod: 'post',
        updateModalPrimaryKey: 'id',
        additionalParams: {
          restaurant_id: this.selected['id']
        },
        items: {
          title: {
            simpleInputType: 'text',
            upperSpanText: 'Заголовок',
            selectedKey: 'title',
          },
          price: {
            simpleInputType: 'number',
            upperSpanText: 'Цена',
            selectedKey: 'price',
          },
          additional_ingredient_group: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/additionalIngredientGroup/getList?restaurantsIds[0]=' + this.selected['id'],
            specifyKeyForLabel: 'title',
            upperSpanText: 'Группа',
            viewLabelSpecified: ['group_type', 'description'],
            selectedKey: 'additional_ingredient_group_id',
          },
          is_active: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Активность',
            placeholder: 'disabled',
            bottomSpanClass: 'slider round',
            selectedKey: 'is_active',
          },
        },
      }
    },
    addModalInputData() {
      return {
        addModalTitle: 'Добавить',
        addModalUrl: '/productAdditionalIngredient/create',
        addModalMethod: 'post',
        additionalParams: {
          restaurant_id: this.selected['id']
        },
        items: {
          title: {
            simpleInputType: 'text',
            upperSpanText: 'Заголовок',
            selectedKey: 'title',
          },
          price: {
            simpleInputType: 'number',
            upperSpanText: 'Цена',
            selectedKey: 'price',
          },
          additional_ingredient_group: {
            multiple: false,
            allowEmpty: true,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/additionalIngredientGroup/getList?restaurantsIds[0]=' + this.selected['id'],
            specifyKeyForLabel: 'title',
            upperSpanText: 'Группа',
            viewLabelSpecified: ['group_type', 'description'],
            selectedKey: 'additional_ingredient_group_id',
          },
          is_active: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Активен',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'is_active',
          },
        },
      }
    }
  }
}
</script>
