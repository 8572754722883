<script>

export default {
  name: "AdditionalFunctions",
  data() {
    return {
      timeout: null
    }
  },
  methods: {
    debounce(fn, ms) {
      return (...args) => {
        const fnCall = () => {
          fn.apply(this, args)
        };
        clearTimeout(this.timeout);
        this.timeout = setTimeout(fnCall, ms);
      };
    }
  },
}
</script>

