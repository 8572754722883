<template>
  <div v-if="userModel">
    <div class="fx fx-start align-center title">
      <h1>
        Клиент {{ userModel.login }}
        <span v-if="!userModel['is_active']" class="text-danger">
          (заблокирован)
        </span>
      </h1>
      <div class="ml-20">
        <vue-spinner color="#CE0000" v-if="showLoader"></vue-spinner>
      </div>
    </div>
    <v-notification>
    </v-notification>
    <div class="row my-3">
      <div class="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-6 mb-2">
        <button
          :class="'btn-t btn--borderColor flex-center' + (showPanelType === 1 ? ' btn--borderColor_hover' : '')"
          @click="showPanelType = 1">
          Основная информация
        </button>
      </div>
    </div>

    <template v-if="showPanelType === 1">
      <div class="row">
        <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="row">

            <div class="col-3 form__field">
              <label id="id">
                <span class="form__field-span">ID:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="id" readonly type="text" :placeholder="userModel.id"/>
            </div>

            <div class="col-3 form__field">
              <label id="phone">
                <span class="form__field-span">Телефон:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="phone" readonly type="text" :placeholder="userModel.phone"/>
            </div>

            <div class="col-3 form__field">
              <label id="login">
                <span class="form__field-span">Логин:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="login" readonly type="text" :placeholder="userModel.login"/>
            </div>

            <div class="col-3 form__field">
              <label id="email">
                <span class="form__field-span">Email:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="email" type="text" v-model="userModel.email"/>
            </div>

            <div class="col-3 form__field">
              <label id="email">
                <span class="form__field-span">Контакт:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="email" type="text" v-model="userModel.contact"/>
            </div>

            <div class="col-3 form__field">
              <label id="created_at">
                <span class="form__field-span">Зареган:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="created_at" readonly type="text" :placeholder="userModel['created_at']"/>
            </div>

            <div class="col-3 form__field">
              <label id="updated_at">
                <span class="form__field-span">Обновлен:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="updated_at" readonly type="text" :placeholder="userModel['updated_at']"/>
            </div>

            <div class="col-3 form__field">
              <label>
                <span class="form__field-span">Дата верификации:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="email_verified_at"
                     readonly
                     type="text"
                     :class="(userModel['email_verified_at'] ? 'green' : 'red')"
                     :value="(userModel['email_verified_at'] ? userModel['email_verified_at'] : 'Клиент не верифицирован')"
              />
            </div>
            <div class="col-3 form__field">
              <label id="roles">
                <span class="">Роли:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <multiselect id="roles"
                           label="name"
                           track-by="id"
                           v-model="userModel['roles']"
                           :options="constantRoles"
                           :placeholder="userModel.hasOwnProperty('roles') ? userModel['roles'].map((e) => e.name).join(', ') : 'Ролей нет'"
                           :closeOnSelect="false"
                           :multiple="true"
                           openDirection="bottom"
                           style="width: 100%"
              ></multiselect>
            </div>
            <div class="col-3 form__field">
              <label id="password">
                <span class="form__field-span">Блокировка: </span>
              </label>
            </div>
            <div class="col-9 form__field">
              <label class="switch">
                <input type="checkbox"
                       placeholder="active"
                       v-model="isBlocked"
                >
                <span class="slider round"></span>
              </label>
            </div>

            <div class="col-3 form__field">
              <label id="password">
                <span class="form__field-span">Пароль:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="password" type="password" v-model="newPassword" placeholder="Новый пароль" autocomplete="off"/>
            </div>

            <div class="fx fx-center mb-20">
              <button class="btn-t btn--borderColor flex-center col-4" @click="updateUser">
                Сохранить данные
              </button>
              <button v-if="!userModel['email_verified_at']"
                      class="btn-t btn--borderColor flex-center col-4 ml-20"
                      @click="verificateUser">
                Верифицировать юзера
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CustomPagination from "../../../components/table/pagination/customPagination.vue";
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin";
import Multiselect from "vue-multiselect";
import Vue from "vue";

export default {
  name: "userCard",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect
  },
  props: ['user'],
  data: function () {
    return {
      userModel: {},
      oldUserModel: {},
      newPassword: null,
      changeBalanceModel: null,
      paymentComment: '',
      perPage: 25,
      showPanelType: 1,
      filterDateCreatedFrom: null,
      filterDateCreatedTo: null,
      filterTimeFrom: null,
      filterTimeTo: null,
      filterStatuses: [],
      filterSearch: null,
      requestLogsFiltered: [],
      roles: [],
      telegramChatId: '',
      constantRoles: [],
      isBlocked: false,
      balanceHistories: [],
      statuses: [],
      langs: [],
      showTable: false,
    }
  },
  async mounted() {
    await this.updateRoleSelector();
    this.userModel = this.user;
    this.oldUserModel = Object.assign({}, this.userModel);
    this.isBlocked = !this.userModel['is_active'];
  },

  methods: {
    toggleTable() {
      this.showTable = !this.showTable;
    },
    async updateRoleSelector() {
      await this.sendRequest('/users/getRoles', 'get').then(response => {
        for (let elem in response) {
          this.constantRoles.push(response[elem]);
        }
      });
    },
    verificateUser: async function () {
      let self = this;
      await this.doWithLoader(() => self.doWithStopRequest(
        async () => {
          let data = {
            id: self.userModel.id,
            verification: true,
          }
          let result = await self.sendRequest('/users/updateUser', 'post', data);
          if (result) {
            self.oldUserModel = Object.assign({}, self.userModel);
            self.userModel = result;
            await self.alertMessage('Клиент успешно верифицирован', 'good');
          }
        }
      ))
    },

    updateUser: async function () {
      let self = this;
      await this.doWithLoader(() => self.doWithStopRequest(
        async () => {
          let data = {id: self.userModel.id};
          if (self.userModel.email != self.oldUserModel.email) {
            data.email = self.userModel.email;
          }
          if (self.newPassword) {
            data.password = self.newPassword;
          }
          data.block = this.isBlocked;
          let oldRoles = self.oldUserModel.roles.map((elem) => elem.name);
          let newRoles = self.userModel.roles.map((elem) => elem.name);
          if (JSON.stringify(oldRoles) != JSON.stringify(newRoles)) {
            let resultChangeRole = await self.sendRequest('/users/updateUserRole','post', {id:self.userModel.id,newRoles});
            if (resultChangeRole) {
              self.oldUserModel = Object.assign({}, self.userModel);
              self.userModel = resultChangeRole;
              self.newPassword = null;
              await self.alertMessage('Роль успешно обновлена', 'good');
            }
            return 1;
          }

          let result = await self.sendRequest('/users/updateUser', 'post', data);
          if (result) {
            self.oldUserModel = Object.assign({}, self.userModel);
            self.userModel = result;
            self.newPassword = null;
            await self.alertMessage('Клиент успешно обновлен', 'good');
          }
        }
      ))
    },
  },

  watch: {

  },
  computed: {
  }
}
</script>

<style scoped>
.ip-table {
  border-collapse: collapse; /* Объединение границ ячеек */
}

.ip-table th,
.ip-table td {
  padding: 8px; /* Отступ внутри ячеек */
  border: 1px solid #ddd; /* Граница ячеек */
}
</style>
