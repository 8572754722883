<script>
import AjaxClient from "../../helpers/AjaxClient";

export default {
  name: "BaseSendRequestMixin",
  data: function () {
    return {
      stopRequest: null,
    }
  },
  methods: {
    async sendRequest(url, method = 'get', data = {}, config = {}) {
      let response;
      const uri = url.split('?')[0];
      const searchParams = url.split('?')[1];
      const additionalParams = {};
      if (searchParams) {
        searchParams.split('&').forEach(param => {
          const keyValue = param.split('=');
          additionalParams[keyValue[0]] = keyValue[1];
        });
      }

      if (data instanceof FormData) {
        for (let key in additionalParams) {
          data.append(key, additionalParams[key]);
        }
      } else {
        data = { ...additionalParams, ...data };
      }

      try {
        response = await (new AjaxClient(this)).send(
            uri,
            method,
            data,
            config
        );
      } catch (e) {
        if (e.name === 'CanceledError') {
          return 'abortError';
        }
        await this.alertMessage(e, 'error');
        return false;
      }
      return response;
    },

    async doWithStopRequest(callback, message) {
      if (this.stopRequest) {
        await this.alertMessage(
            this.stopRequest,
            'error'
        );
        return false;
      }
      this.stopRequest = 'В данный момент выполняется запрос. Пожалуйста подождите. ' + message;
      let result = await callback();
      this.stopRequest = null;

      return result;
    },
  }
}
</script>

<style scoped>

</style>
