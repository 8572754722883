<template>
  <div>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      table-title="Баннеры"
      deleteByIdUrl="/banner/delete"
      deleteByIdMethod="post"
      changeItemWithLoaderMethod="post"
      changeItemWithLoaderUrl="/banner/update"
      :add-modal-input-data="addModalInputData"
      :update-modal-input-data="updateModalInputData"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
          </button>
        </template>
        <template v-else-if="props.column.field === 'is_active'">
          <button v-if="props.row['is_active']" class="btn btn-success w-90"
                  @click="props.row['is_active'] = 0; props.changeItemWithLoader(props.row['id'], {is_active: false})">ДА
          </button>
          <button v-else class="btn btn-danger w-90"
                  @click="props.row['is_active'] = 1; props.changeItemWithLoader(props.row['id'], {is_active: true})">НЕТ
          </button>
        </template>
        <div v-else @click="toggleShowModalUpdate(props)" style="cursor: pointer">
          <template v-if="props.column.field === 'img'">
            <img :src="props.row.img.img_path + '?v=' + props.row.img.updated_at" :alt="props.formattedRow['title']" width="200" height="100">
          </template>
          <template v-else-if="props.column.field === 'img_mobile'">
            <img :src="props.row.img_mobile.img_path + '?v=' + props.row.img_mobile.updated_at" :alt="props.formattedRow['title']" width="200" height="100">
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import BaseTable from "../../../components/table/baseTable";
import ProductsAdditionalIngredientsTable from "../productAdditionalIngredient/productAdditionalIngredientsTable.vue";

export default {
  name: "productsList",
  components: {
    ProductsAdditionalIngredientsTable,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      selectedId: '',
      columns: [
        {
          label: 'Картинка',
          field: 'img',
          type: 'number',
          width: '20%',
          sortable: false,
        },
        {
          label: 'Картинка моб.',
          field: 'img_mobile',
          type: 'number',
          width: '20%',
          sortable: false,
        },
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '5%',
          sortable: true,
        },
        {
          label: 'Заголовок',
          field: 'title',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Сообщение',
          field: 'message',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Активность',
          field: 'is_active',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Удалить',
          field: 'delete',
          type: 'button',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        let imgMobile = null;
        let img = null;
        for (let image of elem.images) {
          if (image.key == 'img') {
            img = image;
          } else if (image.key == 'img_mobile') {
            imgMobile = image;
          }
        }
        data.push({
          id: elem['id'],
          title: elem['title'],
          message: elem['message'],
          url: elem['url'],
          images: elem['images'],
          img_mobile: imgMobile,
          img: img,
          is_active: elem['is_active'],
          updated_at: elem['updated_at']
        });
      }
      return data;
    },
    toggleShowModalUpdate(props) {
      let elem = props.row
      if (elem) {
        this.updateModalInputData.items.title.defaultValue = elem.title
        this.updateModalInputData.items.message.defaultValue = elem.message
        this.updateModalInputData.items.url.defaultValue = elem.url
        this.updateModalInputData.items.is_active.defaultValue = elem.is_active
      }

      props.toggleShowModalUpdate(props.row);
    },
  },
  computed: {
    wrapperFiltersData() {
      return {
        title: "Продукты",
        filtersUrl: "/banner/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          onlyActive: {
            enable: true,
            filterKey: 'onlyActive',
            upperSpanText: 'Только активные: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'onlyActive',
            column: 3
          },
        },
      }
    },
    updateModalInputData() {
      return {
        updateModalTitle: 'Баннер ' + this.selectedId,
        updateModalUrl: '/banner/update',
        updateModalMethod: 'post',
        updateModalPrimaryKey: 'id',
        items:{
          title: {
            simpleInputType: 'text',
            upperSpanText: 'Заголовок',
            defaultValue: '',
            selectedKey: 'title',
          },
          message: {
            simpleInputType: 'text',
            upperSpanText: 'Сообщение',
            defaultValue: '',
            selectedKey: 'message',
          },
          url: {
            simpleInputType: 'text',
            upperSpanText: 'Ссылка',
            defaultValue: '',
            selectedKey: 'url',
          },
          is_active: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Активность',
            placeholder: 'disabled',
            defaultValue: '',
            bottomSpanClass: 'slider round',
            selectedKey: 'is_active',
          },
          img: {
            upperSpanText: 'Картинка',
            simpleInputType: 'file',
            placeholder: 'Img',
            selectedKey: 'img',
          },
          img_mobile: {
            upperSpanText: 'Картинка для мобильных',
            simpleInputType: 'file',
            placeholder: 'Img mobile',
            selectedKey: 'img_mobile',
          },
        }
      }
    },
    addModalInputData() {
      return {
        addModalTitle: 'Добавить',
        addModalUrl: '/banner/create',
        addModalMethod: 'post',
        items:{
          title: {
            simpleInputType: 'text',
            placeholder: 'Заголовок*',
            selectedKey: 'title',
          },
          message: {
            simpleInputType: 'text',
            placeholder: 'Сообщение',
            selectedKey: 'message',
          },
          url: {
            simpleInputType: 'text',
            placeholder: 'Ссылка',
            selectedKey: 'url',
          },
          is_active: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Активность',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'is_active',
          },
          img: {
            upperSpanText: 'Картинка*',
            simpleInputType: 'file',
            placeholder: 'Img',
            selectedKey: 'img',
          },
          img_mobile: {
            upperSpanText: 'Картинка для мобильных*',
            simpleInputType: 'file',
            placeholder: 'Img mobile',
            selectedKey: 'img_mobile',
          },
        }
      }
    }
  }
}
</script>
