<template>
  <div>
    <modal-update
      :update-modal-input-data="updateModalInputData"
      :primary-key-value="selectedId"
      :title="'Группа ' + selectedId"
      @onUpdate="updateList"
      updateModalUrl="/additionalIngredientGroup/update"
      ref="modalUpdateComponent">
    </modal-update>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      table-title="Группы ингредиентов"
      changeItemWithLoaderMethod="post"
      changeItemWithLoaderUrl="/additionalIngredientGroup/update"
      :addModalInputData="addModalInputData"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template v-if="props.column.field === 'required'">
          <button v-if="props.row['required']" class="btn btn-success w-90"
                  @click="props.row['required'] = 0; props.changeItemWithLoader(props.row['id'], {required: false})">ДА
          </button>
          <button v-else class="btn btn-danger w-90"
                  @click="props.row['required'] = 1; props.changeItemWithLoader(props.row['id'], {required: true})">НЕТ
          </button>
        </template>
        <div v-else @click="toggleShowModalUpdate(props.row)" style="cursor: pointer">
          <template v-if="props.column.field === 'restaurant'">
            {{ props.formattedRow[props.column.field]['title'] + ' (' + props.formattedRow[props.column.field]['id'] + ')' }}
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "productsList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props:[
    'user'
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      selectedId: '',
      updateModalInputData: {
        items:{
          restaurant: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/restaurant/getList',
            specifyKeyForLabel: 'title',
            upperSpanText: 'Заведение',
            viewLabel: 'titleWithId',
            selectedKey: 'restaurant_id',
          },
          title: {
            simpleInputType: 'text',
            upperSpanText: 'Title',
            selectedKey: 'title',
          },
          group_type: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/additionalIngredientGroup/getGroupTypeList',
            specifyKeyForLabel: 'description',
            upperSpanText: 'Тип группы',
            viewLabel: 'titleWithId',
            selectedKey: 'group_type',
          },
          required: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Обязателен для выбора',
            placeholder: 'disabled',
            bottomSpanClass: 'slider round',
            selectedKey: 'required',
          },
        }
      },
      addModalInputData: {
        addModalTitle: 'Добавить',
        addModalUrl: '/additionalIngredientGroup/create',
        addModalMethod: 'post',
        items:{
          restaurants: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/restaurant/getList',
            placeholder: 'Выберите заведение*',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            selectedKey: 'restaurant_id',
          },
          title: {
            simpleInputType: 'text',
            placeholder: 'title*',
            selectedKey: 'title',
          },
          group_type: {
            multiple: false,
            allowEmpty: true,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/additionalIngredientGroup/getGroupTypeList',
            specifyKeyForLabel: 'description',
            placeholder: 'Тип группы',
            viewLabel: 'titleWithId',
            selectedKey: 'group_type',
          },
          required: {
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            upperSpanText: 'Обязателен к выбору',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'required',
          },
        }
      },
      wrapperFiltersData: {
        title: "Группы ингредиентов",
        filtersUrl: "/additionalIngredientGroup/getList",
        filtersUrlMethod: "get",
        startWithRequest: false,
        items: {
          restaurants: {
            enable: true,
            multiple: true,
            getDataLink: '/restaurant/getList',
            placeholder: 'Выберите заведение',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            selectedKey: 'restaurantsIds',
            column: 1
          },
          group_type: {
            enable: true,
            multiple: false,
            closeOnSelect: true,
            getDataLink: '/additionalIngredientGroup/getGroupTypeList',
            placeholder: 'Выберите тип группы',
            specifyKeyForLabel: 'description',
            viewLabel: 'titleWithId',
            selectedKey: 'group_type',
            column: 2
          },
          title: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Название',
            selectedKey: 'title',
            column: 3
          },
          required: {
            enable: true,
            filterKey: 'required',
            upperSpanText: 'Только обязательные: ',
            simpleInputType: 'checkbox',
            simpleInputTypeClass: 'switch',
            placeholder: 'disabled',
            setDefaultInFilters: false,
            bottomSpanClass: 'slider round',
            selectedKey: 'onlyRequired',
            column: 3
          },
        },
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '5%',
          sortable: true,
        },
        {
          label: 'Title',
          field: 'title',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Ресторан',
          field: 'restaurant',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Тип группы',
          field: 'group_type_label',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Обязателен',
          field: 'required',
          width: '10%',
          sortable: true,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          group_type_label: elem['group_type']['description'],
          group_type: elem['group_type'],
          required: elem['required'],
          restaurant: elem['restaurant'],
        });
      }
      return data;
    },
    toggleShowModalUpdate(elem) {
      if (elem) {
        this.selectedId = elem.id
        this.updateModalInputData.items.title.defaultValue = elem.title
        this.updateModalInputData.items.required.defaultValue = elem.required
        this.updateModalInputData.items.group_type.defaultValue = elem.group_type['id']
        this.updateModalInputData.items.restaurant.defaultValue = elem.restaurant['id']
      }

      if (this.$refs.modalUpdateComponent) {
        this.$refs.modalUpdateComponent.toggleShowModalUpdate();
      }
    },
    updateList() {
      this.$root.$emit('update-list')
    }
  },
}
</script>
