<template>
  <div>
    <div>
      <h1>
        Алерты к боту: {{ alertList['bot_name'] }}
      </h1>
    </div>
    <div class="form-group">
      <label for="form-control">Токент бота:</label>
      <input type="text" class="form-control" v-model="alertList['bot_token']" @keyup.enter="updateBotToken(alertList['bot_token'])">
    </div>
    <telegram :list-to-show="this.alertList['telegram_alert']" :href-url="'/telegram/getUsersList/'">
    </telegram>
  </div>
</template>
<script>
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
export default {
  name: "alert-list",
  mixins: [
    BaseAdminMixin
  ],
  props:[
    'alertList',
  ],
  methods: {
    async updateBotToken(newToken){
      let response = await this.sendRequest('/telegram/updateBotToken','post',{botId: this.alertList['id'], bot_token: newToken})
      if(response?.status == 'ok'){
        await this.alertMessage('Token updated');
      }
    }
  }
}
</script>
