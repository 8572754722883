<template>
  <div>
    <v-notification>
    </v-notification>
    <Modal
        v-show="showModalUpdate"
        :show="showModalUpdate"
        v-if="showModalUpdate"
        :key="primaryKeyValue"
        :scrollable="true"
        header-id="modalUpdateHeader"
        body-id="modalUpdateBody"
        @close="toggleShowModalUpdate"
    >
      <template #header>
        {{ title }}
      </template>
      <template #body>
          <instance-input-select
            v-for="(addInput, addInputName) in prepareDataToUpdate"
            v-if="showModalUpdate"
            :key="addInputName"
            :index="addInputName"
            :object="addInput"
            @add-filter-id-arr-single="addFilterId"
            @add-filter-data-single="addFilterSingle"
            @add-filter-ids-arr="addFilterIdsArray"
            @remove-filter-id="removeFilterId"
            @remove-filter-ids-arr="removeFilterIdsArray">
          </instance-input-select>
        <button class="col-12 btn-t btn--borderColor flex-center" @click="update">Обновить</button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from './modal.vue';
import BaseAdminMixin from "../mixins/BaseAdminMixin.vue";
import AddRemoveSelectedMixin from '../mixins/AddRemoveSelectedMixin.vue'
import InstanceInputSelect from "./instanceInputSelect.vue";

export default {
  name: "modalAdd",
  mixins: [
    BaseAdminMixin,
    AddRemoveSelectedMixin
  ],
  components: {
    Modal,
    InstanceInputSelect
  },
  props: {
    updateModalInputData: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: {}
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    primaryKeyValue: {
      required: true,
    },
    updateModalUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showModalUpdate: false,
      additionalParams: {},
      updateModalItems: {},
    }
  },
  methods: {
    toggleShowModalUpdate() {
      this.showModalUpdate = !this.showModalUpdate;
    },
    clearObjects(addParams) {
      let data = {};
      for (let filter in addParams) {
        if (Array.isArray(addParams[filter]) && addParams[filter].length) {
          data[filter] = addParams[filter];
        } else {
          data[filter] = addParams[filter];
        }
      }
      return data;
    },
    update() {
      this.timeout = setTimeout(
          () => this.doWithStopRequest(
              () => this.updateRequest(), 'Updating'),
          1
      );
    },
    async updateRequest() {
      let data = this.clearObjects(this.selected)
      const formData = new FormData()

      for (let key in data) {
        if (data[key] && data[key].constructor === Array) {
          for (let key2 in data[key]) {
            formData.append(key + '[]', data[key][key2])
          }
        } else {
          formData.append(key, data[key])
        }
      }
      if (this.addModalInputData) {
        if (this.addModalInputData.additionalParams) {
          for (let key in this.addModalInputData.additionalParams) {
            formData.append(key, this.addModalInputData.additionalParams[key])
          }
        }
      }
      formData.append(this.primaryKey, this.primaryKeyValue);
      let response = await this.sendRequest(
          this.updateModalUrl,
          'post',
          formData,
          {'Content-Type': 'multipart/form-data'}
      );
      if (response) {
        await this.alertMessage('Successfully updated')
        this.$emit('onUpdate')
        this.toggleShowModalUpdate()
      }
    },
  },
  computed: {
    prepareDataToUpdate() {
      let data = {};
      for (let elem in this.updateModalInputData.items) {
        let obj = this.updateModalInputData.items[elem]
        if (!obj.placeholder) {
          obj.placeholder = ''
        }
        if (!obj.selectedKey) {
          obj.selectedKey = elem + 'Ids'
        }
        data[elem] = obj
      }

      return data
    }
  },
  watch: {
    primaryKeyValue(newValue, oldValue) {
      this.selected = {}
    }
  }
}
</script>

<style scoped>

</style>
