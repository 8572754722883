<template>
  <div class="v-notification">
    <notifications position="top right">
      <template v-slot:body="{item, close}">
        <div
            :key="item.id"
            class="v-notification__content"
            :class="item.type"
        >
          <div class="content__text">
            <div v-html="item.text"></div>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
export default {
  name: "vNotification",
}
</script>

<style lang="scss">
.v-notification {
  position: fixed;
  top: 80px;
  right: 16px;
  z-index: 9999;

  &__messages_list {
    display: flex;
    flex-direction: column-reverse;
  }

  &__content {
    font-size: 20px;
    padding: 16px;
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    margin-bottom: 16px;
    background: #5cb85c;

    &.error {
      background: #f23958;
    }

    &.warning {
      background: darkorange;
    }

    &.good {
      background: #5cb85c;
    }
    &.info {
      background: #5cb85c;
    }
  }

  .content {
    &__text {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.v-transition-animate {
  &-enter {
    transform: translateX(120px);
    opacity: 0;
  }

  &-enter-active {
    transition: all .6s ease;
  }

  &-enter-to {
    opacity: 1;
  }

  &-leave {
    height: 50px;
    opacity: 1;
  }

  &-leave-active {
    transition: transform .6s ease, opacity .6s, height .6s .2s;
  }

  &-leave-to {
    height: 0;
    transform: translateX(120px);
    opacity: 0;
  }

  &-move {
    transition: transform .6s ease;
  }
}
</style>
