<template>
  <div>
    <Modal
      v-show="showModalIngredients"
      :show="showModalIngredients"
      v-if="showModalIngredients"
      :scrollable="true"
      header-id="modalIngredientsHeader"
      body-id="modalIngredientsBody"
      @close="toggleShowModal">
      <template #header>
        {{ title }}
      </template>
      <template #body>
        <base-table
          v-if="showModalIngredients"
          :render-list-to-show="listToShow"
          :per-page="50"
          :wrapper-filters-data="wrapperFiltersData"
          :columns="columns"
          :table-title="tableTitle"
          :update-list-on-add="true"
          deleteByIdUrl="/product/deleteAdditionalIngredientLink"
          deleteByIdMethod="post"
          delete-by-id-key="product_additional_ingredient_id"
          changeItemWithLoaderMethod="post"
          changeItemWithLoaderUrl="/productAdditionalIngredient/update"
          :delete-additional-params="deleteAdditionalParams"
          :add-modal-input-data="addModalInputData"
        >
          <template v-slot:base-table-actions="props">
            <div class="topping__btns fx col-12">
              <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
            </div>
          </template>
          <template v-slot:base-table-row="props">
            <template v-if="props.column.field === 'delete'">
              <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
              </button>
            </template>
            <template v-else-if="props.column.field === 'additional_ingredient_group'">
              {{
                props.formattedRow[props.column.field]['title'] + ' (' + props.formattedRow[props.column.field]['id'] + ')'
              }}
            </template>
            <template v-else-if="props.column.field === 'is_active'">
              <span :class="props.formattedRow[props.column.field] ? 'text-success' : 'text-danger'">{{ props.formattedRow[props.column.field] ? 'ДА' : 'НЕТ' }}</span>
            </template>
            <template v-else>
              {{ props.formattedRow[props.column.field] }}
            </template>
          </template>
        </base-table>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import BaseTable from "../../../components/table/baseTable.vue";

export default {
  name: "productsList",
  components: {
    BaseTable,
    BaseAdminMixin,
    Modal,
  },
  mixins: [
    BaseAdminMixin,
  ],
  props: {
    productId: {
      default: null
    },
    restaurantId: {
      default: null
    },
    title: {
      type: String,
      default: null
    },
  },
  data: function () {
    return {
      showModalIngredients: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '5%',
          sortable: true,
        },
        {
          label: 'Заголовок',
          field: 'title',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Цена',
          field: 'price',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Группа',
          field: 'additional_ingredient_group',
          sortable: true,
        },
        {
          label: 'Активен',
          field: 'is_active',
          width: '13%',
          sortable: true,
        },
        {
          label: 'Удалить',
          field: 'delete',
          width: '13%',
          sortable: true,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          price: elem['price'],
          is_active: elem['is_active'],
          restaurant: elem['restaurant'],
          additional_ingredient_group: elem['additional_ingredient_group']
        });
      }
      return data;
    },
    toggleShowModal() {
      this.showModalIngredients = !this.showModalIngredients;
    },
  },
  computed: {
    tableTitle() {
      return ''
    },
    wrapperFiltersData() {
      return {
        filtersUrl: "/productAdditionalIngredient/getList?productId=" + this.productId + '&restaurantId=' + this.restaurantId,
        filtersUrlMethod: "get",
        startWithRequest: true,
      }
    },
    addModalInputData() {
      return {
        addModalTitle: 'Добавить',
        addModalUrl: '/product/createAdditionalIngredientLink',
        addModalMethod: 'post',
        additionalParams: {
          product_id: this.productId
        },
        items: {
          product_additional_ingredient: {
            multiple: false,
            allowEmpty: true,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/productAdditionalIngredient/getList?restaurantId=' + this.restaurantId,
            specifyKeyForLabel: 'title',
            upperSpanText: 'Ингредиент',
            viewLabelSpecified: ['additional_ingredient_group', 'title'],
            selectedKey: 'product_additional_ingredient_id',
          },
        },
      }
    },
    deleteAdditionalParams() {
      return {
        product_id: this.productId
      }
    }
  }
}
</script>
