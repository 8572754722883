<template>

</template>

<script>
import BaseSendRequestMixin from "../../components/mixins/BaseSendRequestMixin";
import BaseMessagingMixin from "../../components/mixins/BaseMessagingMixin";
import BaseLoaderMixin from "../../components/mixins/BaseLoaderMixin";

export default {
  name: "BaseAdminMixin",
  mixins: [
      BaseSendRequestMixin,
      BaseMessagingMixin,
      BaseLoaderMixin
  ],
}
</script>

<style scoped>

</style>
