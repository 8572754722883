<template>
  <div>
    <base-table
      ref="baseTable"
      :columns="this.columns"
      :per-page="250"
      :wrapperFiltersData="this.wrapperFiltersData"
      :render-list-to-show="this.listToShow"
      deleteByIdUrl="/users/delete"
      deleteByIdMethod="post"
      :use-admin-pagination="true"
      :use-custom-pagination="true"
    >
      <template v-slot:base-table-row="props">
        <a :href="'/users/' + props.row.id">
          <div :class="'admin-table-element' + (props.row['is_active'] ? ' ' : ' text-danger') ">
            <template v-if="props.column.field === 'balance' || props.column.field === 'reserved_balance' || props.column.field === 'referral_balance'">
                <span :class="props.row[props.column.field] > 0 ? 'green' : 'red'">
                  {{props.row[props.column.field] }}<br></span>
            </template>
            <template v-else>
              {{ props.formattedRow[props.column.field] }}
            </template>
          </div>
        </a>
        <template v-if="props.column.field == 'delete'">
          <button type="button" class="btn btn-danger" @click="props.deleteById(props.row['id'])">Удалить
          </button>
        </template>
      </template>
      <template v-slot:base-table-actions-bottom>
        <div class="fx fx-start fx-wrap mt-40">
          <label class="form__field mr-10 col-12 col-lg-2">
            <input type="text" placeholder="Логин" v-model="newUserModel.login"/>
          </label>
          <label class="form__field mr-10 col-12 col-lg-2">
            <input type="text" placeholder="Email" v-model="newUserModel.email"/>
          </label>
          <label class="form__field mr-10 col-12 col-lg-2">
            <input type="text" placeholder="Телефон" v-model="newUserModel.phone"/>
          </label>
          <label class="form__field mr-10 col-12 col-lg-2">
            <input type="password" placeholder="Пароль" v-model="newUserModel.password"/>
          </label>
          <button class="btn-t btn--borderRed flex-center col-12 col-lg-2" @click="createUser">Создать
            пользователя
          </button>
        </div>
      </template>
    </base-table>

  </div>
</template>

<script>
import CustomPagination from "../../../components/table/pagination/customPagination.vue";
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Modal from '../../../components/table/modal.vue';
import BaseTable from "../../../components/table/baseTable.vue";

export default {
  name: "serviceEditTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    BaseTable,
    CustomPagination,
    Modal
  },
  data: function () {
    return {
      filteredList: [],
      wrapperFiltersData: {
        title: "Список пользователей",
        filtersUrl: "/users/getUsers",
        startWithRequest: true,
        filtersUrlMethod: "get",
        sendFilterRequestAfterSelect: true,
        items: {
          date: {
            enable: true,
            column: 1,
          },
          users: {
            enable: true,
            multiple: true,
            asyncRenderList: true,
            getDataLinkAsyncRequest: '/users/getUsersForFiltersByRequest',
            placeholder: 'Пользователи',
            specifyKeyForLabel: 'login',
            viewLabel: 'titleWithId',
            column: 2
          },
          roles: {
            enable: true,
            multiple: true,
            getDataLink: '/users/getRoles',
            placeholder: 'Роли',
            specifyKeyForLabel: 'name',
            viewLabel: 'titleWithId',
            column: 3
          },
          searchByEmail: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Поиск по email',
            selectedKey: 'searchByEmail',
            column: 1
          },
          searchById: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Поиск по id',
            selectedKey: 'searchById',
            column: 2
          }
        },
      },
      tableTitle: 'Users',
      columns: [
        {
          label: 'ID⠀⠀',
          field: 'id',
          type: 'number',
          width: '5%',
        },
        {
          label: 'Телефон⠀',
          field: 'phone',
          type: 'number',
          width: '10%',
        },
        {
          label: 'Логин⠀⠀',
          field: 'login',
          width: '10%',
        },
        {
          label: 'Email',
          field: 'email',
          width: '15%',
        },
        {
          label: 'Дата регистрации',
          field: 'created_at',
          width: '18%',
        },
        {
          label: 'Роли⠀⠀',
          field: 'roles',
          width: '18%',
        },
        {
          label: 'Удалить',
          field: 'delete',
        },
      ],
      filters: {},
      showModalAdd: false,
      newUserModel: {
        login: null,
        email: null,
        password: null,
        phone: null,
      },
    };
  },

  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem in list['data']) {
        data.push({
          id: list['data'][elem]['id'],
          login: list['data'][elem]['login'],
          email: list['data'][elem]['email'],
          phone: list['data'][elem]['phone'],
          created_at: list['data'][elem]['created_at'],
          is_active: list['data'][elem]['is_active'],
          roles: list['data'][elem]['roles'] ?  list['data'][elem]['roles'].map(function (elem) { return elem.name }).join(', ') : '',
        });
      }
      return data;
    },
    async createUser() {
      let result = await this.sendRequest(
        '/users/createUser',
        'post',
        this.newUserModel
      );

      if (result) {
        await this.alertMessage('Пользователь успешно создан', 'good');
        this.$refs.baseTable.pasteNewSingleItem(result);
      }
    },
  },
}
</script>

<style scoped>

</style>
