<template>
  <!-- Pagination -->
  <nav class="pagination fx fx-center">
    <template v-if="totalPages > 1">
      <div class="pagination-select-page dropdown">
        <button @click="toggleDropdown" class="dropdown-toggle">{{ perPageDefault }}</button>
        <ul v-if="isOpen" class="unordered-pagination-list">
          <li v-for="option in options" :key="option.value" style="list-style: none;">
            <button @click="customPerPerChange(option.value)" class="pagination-option">{{ option.label }}</button>
          </li>
        </ul>
      </div>
    </template>
    <div class="pagination__list fx fx-center">
      <div class="fx position-relative pagination-list">
        <div v-if="totalPages > 1 && currentPage !== 1" class="pagination__item pagination__item--btn">
          <button class="pagination__link pagination_link_prev" v-on:click="customPageChange(currentPage - 1)">
            <svg viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.909043 12C0.909043 11.7211 1.01568 11.4418 1.22868 11.2288L12.1378 0.319704C12.564 -0.106568 13.2543 -0.106568 13.6803 0.319704C14.1063 0.745976 14.1066 1.43625 13.6803 1.86225L3.54249 12L13.6803 22.1379C14.1066 22.5641 14.1066 23.2544 13.6803 23.6804C13.254 24.1064 12.5638 24.1067 12.1378 23.6804L1.22868 12.7713C1.01568 12.5583 0.909043 12.279 0.909043 12Z"/>
            </svg>
          </button>
        </div>
        <template v-for="page in pages">
          <div v-if="
                    totalPages > 1
                    && (
                      ((page + 2) >= currentPage && page <= currentPage)
                      || ((page - 2) <= currentPage && page > currentPage)
                      || ((currentPage - 2) <= 0 && page <= 5)
                      || ((currentPage + 3) >= totalPages && page >= totalPages - 6)
                    )
            " class="pagination__item"
          >
            <button :class="'pagination__link' + (page === currentPage ? ' pagination__link--active' : '')" v-on:click="customPageChange(page)">{{ page }}</button>
          </div>
        </template>
        <div v-if="totalPages - 4 >= currentPage" class="pagination__item">
          <button class="pagination__link" v-on:click="customPageChange(currentPage + 3 + ((currentPage - 3 < 0) ? -(currentPage - 3) : 0))">...</button>
        </div>
        <div v-if="totalPages - 4 >= currentPage" class="pagination__item">
          <button class="pagination__link" v-on:click="customPageChange(totalPages)">{{ totalPages }}</button>
        </div>
        <div v-if="totalPages > 1 && currentPage !== totalPages" class="pagination__item pagination__item--btn">
          <button class="pagination__link pagination_link_next" v-on:click="customPageChange(currentPage + 1)">
            <svg viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M13.091 12C13.091 12.2789 12.9843 12.5582 12.7713 12.7712L1.86225 23.6803C1.43597 24.1066 0.745703 24.1066 0.319704 23.6803C-0.106295 23.254 -0.106568 22.5638 0.319704 22.1378L10.4575 12L0.319704 1.86215C-0.106568 1.43587 -0.106568 0.745602 0.319704 0.319603C0.745976 -0.106398 1.43625 -0.10667 1.86225 0.319603L12.7713 11.2287C12.9843 11.4417 13.091 11.721 13.091 12Z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "customPagination",
  props: {
    total: { type: Number, required: true },
    perPage: { type: Number, required: true },
    pageChanged: { type: Function },
    perPageChanged: { type: Function },
  },
  data() {
    return {
      currentPage: 1,
      perPageDefault: 25,
      isOpen: false,
      options: [
        { value: 15, label: "15" },
        { value: 25, label: "25" },
        { value: 50, label: "50" },
        { value: 100, label: "100" },
        { value: 300, label: "300" },
      ],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    pages() {
      let pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    customPageChange(customCurrentPage) {
      if (this.pageChanged) {
        this.pageChanged({ currentPage: customCurrentPage,perPage: this.perPageDefault });
      }
      this.currentPage = customCurrentPage;
    },
    customPerPerChange(perPage) {
      if (this.perPageChanged) {
        this.perPageChanged({ currentPage: this.currentPage, perPage: perPage });
      }
      this.perPageDefault = perPage;
      this.isOpen = false;
    },
  },
}
</script>

<style scoped>
.dropdown {
  position: absolute;
  display: inline-block;
  margin-bottom: 20px;
}

.pagination-option{
  width: 70px;
  background-color: #093828;
  border: none;
  color: white
}

.pagination-option:hover{
  background-color: #209E73;
}

.dropdown-toggle {
  background-color: #209E73;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.unordered-pagination-list{
  display:inline;
}

@media screen and (max-width: 700px) {
  .pagination {
    flex-direction: column;
  }

  .pagination__list {
    overflow-x: scroll;
    white-space: nowrap;
    width: 30px;
  }

  .pagination__item {
    display: inline-block;
  }

  .pagination-list{
    flex-direction: row !important;
  }
  .pagination__item--btn:first-child{
    left: auto !important;
  }
  .pagination__item--btn:last-child{
    right: -90px;
  }

  .dropdown {
    display: contents !important;
  }

  .unordered-pagination-list{
    display:inherit;
  }
  .pagination-option{
    width: 100%;
  }
}

@media screen and (max-width: 500px),
screen and (width: 390px),
screen and (width: 414px),
screen and (max-width: 375px),
screen and (max-width: 320px) {
  .pagination__item--btn:last-child{
    right: -250px; /* Выберите наибольшее значение для right */
  }
}
</style>