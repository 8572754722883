<template>
  <telegram :list-to-show="this.listToShow" :href-url="'/telegram/alerts/'">
  </telegram>
</template>
<script>
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import CustomPagination from "../../../components/table/pagination/customPagination";
import Multiselect from "vue-multiselect";
export default {
  name: "telegram-bots",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
  },
  data: function () {
    return {
      telegramBots: [],
      selected: [],
      perPage: 50,
      languageSelect: []
    }
  },
  async mounted() {
    await this.loadBots();
  },
  methods:{
    async loadBots(){
      this.telegramBots = await this.sendRequest('/telegram/getBotsList');
    }
  },
  computed: {
    listToShow: function () {
      let data = [];
      if(this.telegramBots.length != 0){
        for (let bot of this.telegramBots) {
          data.push({
            id: bot['id'],
            name: bot['bot_name'],
            description: bot['description'],
          })
        }
      }
      return data;
    },
  }
}
</script>
