<template>
  <div class="pagination">
    <div class="pagination-count-left">
      <form>
        <label for="select-per-page-count">Rows per page:</label>
        <select name="perPageSelect" id="select-per-page-count" class="per-page-dropdown" @change="customPerPageChange">
          <option v-for="option in options" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </form>
    </div>

    <div class="pagination-navigation">
      <div class="pagination-info">
        <div>
          {{getStartElement()}} - {{getEndElement()}} of {{total}}
        </div>
      </div>
      <button type="button"
              class="pagination-back-navigation first-page-anchor"
              :disabled="currentPage == 1"
              @click="customPageChange(1)">
        <span>First</span>
      </button>
      <button type="button"
              class="pagination-back-navigation"
              :disabled="currentPage == 1"
              @click="customPageChange(currentPage-1)">
        <span class="chevron left"></span>
        <span>Previous</span>
      </button>

      <button type="button"
              class="pagination-next-navigation"
              :disabled="lastPage == currentPage"
              @click="customPageChange(currentPage+1)">
        <span>Next</span>
        <span class="chevron right"></span>
      </button>

      <button type="button"
              class="pagination-next-navigation last-page-anchor"
              :disabled="lastPage == currentPage"
              @click="customPageChange(lastPage)">
        <span>Last</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "customPagination",
  props: {
    total: { type: Number, required: true },
    perPage: { type: Number, required: true },
    lastPage: { type: Number, required: true },
    pageChanged: { type: Function },
    perPageChanged: { type: Function },
  },
  data() {
    return {
      currentPage: 1,
      perPageDefault: 25,
      options: [
        { value: 25, label: "25" },
        { value: 15, label: "15" },
        { value: 50, label: "50" },
        { value: 100, label: "100" },
        { value: 300, label: "300" },
      ],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    pages() {
      let pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    customPageChange(customCurrentPage) {
      if (this.pageChanged) {
        this.pageChanged({ currentPage: customCurrentPage,perPage: this.perPageDefault });
      }
      this.currentPage = customCurrentPage;
    },
    customPerPageChange(event) {
      let perPage = event.target.value;
      if (this.perPageChanged) {
        this.perPageChanged({ currentPage: this.currentPage, perPage: perPage });
      }
      this.perPageDefault = perPage;
    },
    getStartElement() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    getEndElement() {
      if(this.lastPage == this.currentPage){
        return this.total;
      }
      return this.currentPage * this.perPage;
    }
  },
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #606266;
  font-size: 1.1rem;
  padding: 1em;
  border: 1px solid #dcdfe6;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.pagination-count-left {
  display: flex;
  align-items: center;
}

.pagination-navigation {
  display: flex;
  align-items: center;
}

.pagination-info {
  color: #909399;
  margin: 0 16px;
}

.pagination-back-navigation {
  margin-right: 16px;
}

.chevron-left{
  width: 24px;
  height: 24px;
  border-radius: 15%;
  position: relative;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}
.chevron.left:after {
  border-right-color: #606266;
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;

  border-right: 6px solid #409eff;
  margin-left: -3px;
}

.pagination-next-navigation{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
  border: none;
  text-decoration: none;
  /*color: #606266;*/
  font-weight: 700;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
}

.chevron{
  width: 24px;
  height: 24px;
  border-radius: 15%;
  position: relative;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}
.chevron:after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.chevron.right::after {
  border-left: 6px solid #409eff;
  margin-left: -3px;
}

.chevron.left:after {
  border-right-color: #606266;
  border-right: 6px solid #409eff;
  margin-left: -3px;
}

.last-page-anchor{
  margin-left: 16px;
}
</style>