<template>
    <div v-if="this.object.simpleInputType !== undefined" class="fx align-center">
      <span v-if="this.object.upperSpanText" :class="this.object.upperSpanText ? 'col-3' : ''">{{this.object.upperSpanText}}</span>
      <label :class="this.object.simpleInputTypeClass + ' form__field ' + (this.object.upperSpanText ? 'col-9' : 'col-12')">
        <textarea
          v-if="this.object.simpleInputType === 'textarea'"
          @change="addFilterComponentValueSimple($event)"
          :placeholder="this.object.placeholder"
          v-model="filteredList"
        ></textarea>
        <input
          v-else
          :type="this.object.simpleInputType"
          @change="addFilterComponentValueSimple($event)"
          :placeholder="this.object.placeholder"
          v-model="filteredList"
        >
        <span :class="this.object.bottomSpanClass ? this.object.bottomSpanClass : ''"></span>
      </label>
    </div>
    <div v-else class="fx align-center">
      <span :class="this.object.upperSpanText ? 'col-3' : ''" v-if="this.object.upperSpanText">{{this.object.upperSpanText}}</span>
      <div :class="this.object.upperSpanText ? 'col-9' : 'col-12'">
        <multiselect
          v-if="this.object.noGroupSelect === true"
          v-model="filteredList"
          :value="filteredList"
          @select="addFilterIdComponentHandler($event)"
          @remove="removeFilterIdComponentHandler($event)"
          :allow-empty="this.object.allowEmpty !== undefined ? this.object.allowEmpty : true"
          :clear-on-select="this.object.clearOnSelect !== undefined ? this.object.clearOnSelect : true"
          :placeholder="this.object.placeholder"
          :label="this.object.label  ? this.object.label: 'title'"
          :track-by="this.object.id ? this.object.id : 'id'"
          :close-on-select="this.object.closeOnSelect !== undefined ? this.object.closeOnSelect : false"
          :show-labels="this.object.showLabels !== undefined ? this.object.showLabels: false"
          :options="this.renderList"
          :multiple="this.object.multiple !== undefined ? this.object.multiple : false"
          :open-direction="this.object.openDirection ? this.object.openDirection : 'bottom'"
        ></multiselect>
        <multiselect
          v-else-if="this.object.asyncRenderList === true"
          v-model="filteredList"
          :value="filteredList"
          @search-change="asyncRequestForRenderList"
          @select="addFilterIdComponentHandler($event)"
          @remove="removeFilterIdComponentHandler($event)"
          :loading="isLoading"
          :searchable="true"
          :internal-search="false"
          :options-limit="300"
          open-direction="bottom"
          :multiple="true"
          :clear-on-select="false"
          :close-on-select="false"
          :max-height="600"
          :show-no-results="false"
          :allow-empty="this.object.allowEmpty !== undefined ? this.object.allowEmpty : true"
          :placeholder="this.object.placeholder"
          :label="this.object.label  ? this.object.label: 'title'"
          :track-by="this.object.id ? this.object.id : 'id'"
          :showLabels="this.object.showLabels !== undefined ? this.object.showLabels: false"
          :options="this.renderList"
          :openDirection="this.object.openDirection ? this.object.openDirection : 'bottom'"
        >
          <template v-slot:noOptions><span>Начните вводить</span></template>
        </multiselect>
        <multiselect
          v-else
          v-model="filteredList"
          @select="addFilterIdComponentHandler($event)"
          @remove="removeFilterIdComponentHandler($event)"
          :clear-on-select="this.object.clearOnSelect !== undefined ? this.object.clearOnSelect : true"
          :placeholder="this.object.placeholder"
          :label="this.object.label  ? this.object.label: 'title'"
          :track-by="this.object.id ? this.object.id : 'id'"
          group-values="list"
          group-label="group"
          :group-select="true"
          :showLabels="this.object.showLabels !== undefined ? this.object.showLabels: false"
          :options="getGroupList()"
          :multiple="this.object.multiple !== undefined ? this.object.multiple : false"
          :openDirection="this.object.openDirection ? this.object.openDirection : 'bottom'"
        ></multiselect>
      </div>
    </div>
</template>

<script>
import BaseAdminMixin from "../mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../assets/css/multiselectCustomization.css';

export default {
  name: "oneInstanceInputComponent",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    Multiselect,
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    index: {
      type: String,
      required: true
    },
  },
  async mounted() {
    this.hostNameOrigin = window.location.origin
    await this.updateSelectorFilters()
  },

  data() {
    return {
      filteredList: [],
      renderList: [],
      hostNameOrigin: null,
      keyNameForLabels: 'title',
      specifiedKeyRender: 'id',
      isLoading: false
    }
  },
  methods: {
    clearFilteredData() {
      this.filteredList = [];
    },
    setDataRenderFiltersList(data) {
      this.renderList = data;
    },
    async updateSelectorFilters() {
      if (this.object.specifyKeyForLabel) {
        this.keyNameForLabels = this.object.specifyKeyForLabel
      }

      if (this.object.setDefaultInFilters !== undefined) {
        this.$emit('add-filter-data-single', this.object.selectedKey, this.object.setDefaultInFilters)
      }

      if (this.object.data) {
        this.renderList = this.getTypeRender(this.object.data);
      } else if (this.object.getDataLink) {
        this.renderList = this.getTypeRender(await this.sendRequest(this.object.getDataLink));
      }

      if (this.object.defaultValue) {
        if (this.object.simpleInputType !== undefined) {
          this.filteredList = this.object.defaultValue
        } else {
          for (let elem of this.renderList) {
            if (this.object.defaultValue.constructor === Array) {
              let values = this.object.defaultValue.map(item => item.id);
              if (values.includes(elem['id'])) {
                this.filteredList.push(elem);
              }
            } else {
              if (elem['id'] == this.object.defaultValue) {
                this.filteredList = elem;
                break;
              }
            }
          }
        }
      }
    },
    addFilterComponentValueSimple(event) {
      let value = event.target.value
      if (event.target.type === 'checkbox') {
        value = event.target.checked
      } else if (event.target.type === 'file') {
        value = event.target.files[0]
      }

      return this.$emit('add-filter-data-single', this.object.selectedKey, value);
    },
    addFilterIdComponentHandler(event) {
      if (!this.object.multiple) {
        return this.$emit('add-filter-data-single', this.object.selectedKey, event.id);
      }
      if (Array.isArray(event)) {
        return this.$emit('add-filter-ids-arr', this.object.selectedKey, event);
      }
      this.$emit('add-filter-id-arr-single', this.object.selectedKey, event.id);
    },
    removeFilterIdComponentHandler(event) {
      if (Array.isArray(event)) {
        return this.$emit('remove-filter-ids-arr', this.object.selectedKey);
      }
      this.$emit('remove-filter-id', this.object.selectedKey, event.id);

    },
    getTypeRender(list) {
      if (this.object.viewLabel && this.object.viewLabel === 'titleWithId') {
        return this.standardizedListWithKey(list);
      }
      if (this.object.viewLabelSpecified) {
        this.specifiedKeyRender = this.object.viewLabelSpecified;
        return this.standardizedListWithKey(list);
      }
      return this.standardizedList(list);
    },
    getGroupList() {
      return [
        {
          group: 'Выбрать все',
          list: this.renderList
        },
      ];
    },
    async asyncRequestForRenderList(searchQuery) {
      if (searchQuery.length < 2) {
        return;
      }
      if (this.object.getDataLinkAsyncRequest) {
        this.isLoading = true;
        this.renderList = this.prepareLabelTitleWithKey(
          await this.sendRequest(this.object.getDataLinkAsyncRequest,
            'get', {query:searchQuery}));
        this.isLoading = false;
      }
    },
    prepareLabelTitleWithKey(list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem[this.keyNameForLabels] + ' (' + elem[this.specifiedKeyRender] + ')',
        });
      }
      return data;
    },
    standardizedList(list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem[this.keyNameForLabels],
        });
      }
      return data;
    },
    standardizedListWithKey(list) {
      let data = [];
      for (let elem of list) {
        let specificKey = elem;
        if (this.specifiedKeyRender.constructor === Array) {
          for (let value of this.specifiedKeyRender) {
            specificKey = specificKey[value]
          }
        } else {
          specificKey = specificKey[this.specifiedKeyRender]
        }

        data.push({
          id: elem['id'],
          title: elem[this.keyNameForLabels] + ' (' + specificKey + ')',
        });
      }
      return data;
    },
  },
  computed: {

  },
}
</script>

<style>
.multiselect__spinner {
  right: 31px!important;
}
</style>
