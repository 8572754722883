<template>
  <div>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      table-title="Хронология"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <template>
          {{ props.formattedRow[props.column.field] }}
        </template>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "chronologyList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  props:[],
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Хронология",
        filtersUrl: "/chronology/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 2,
                title: 'За последний час'
              },
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
            ],
            column: 1,
            defaultValue: {
              id: 2,
              title: 'За последний час'
            },
            setDefaultInFilters: 2
          },
          users: {
            enable: true,
            multiple: true,
            asyncRenderList: true,
            getDataLinkAsyncRequest: '/users/getUsersForFiltersByRequest',
            placeholder: 'Введите пользователя',
            specifyKeyForLabel: 'login',
            viewLabel: 'titleWithId',
            column: 2
          },
          objectId: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'ID объекта хронологии',
            selectedKey: 'objectId',
            column: 2,
          },
          types: {
            enable: true,
            multiple: true,
            getDataLink: '/chronology/getTypeList',
            placeholder: 'Выберите тип хронологии',
            specifyKeyForLabel: 'description',
            viewLabel: 'type',
            selectedKey: 'types',
            column: 3
          },
        },
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          width: '5%',
          sortable: true,
        },
        {
          label: 'Тип хронологии',
          field: 'chronology_type',
          width: '13%',
          sortable: false,
        },
        {
          label: 'ID объекта',
          field: 'chronology_object_id',
          width: '8%',
          type: 'number',
          sortable: true,
        },
        {
          label: 'Кто изменил',
          field: 'user',
          width: '12%',
          sortable: true,
        },
        {
          label: 'Описание изменения',
          field: 'description',
          width: '12%',
          sortable: true,
        },
        {
          label: 'Старые данные',
          field: 'old_data',
          width: '18%',
          sortable: false,
        },
        {
          label: 'Новые данные',
          field: 'new_data',
          width: '18%',
          sortable: false,
        },
        {
          label: 'Дата',
          field: 'created_at',
          html: true
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          chronology_type: elem['chronology_type']['description'],
          chronology_object_id: elem['chronology_object_id'],
          user: elem['user']['email'] + ' (' + elem['user']['id'] + ')',
          description: elem['description'],
          old_data: elem['old_data'],
          new_data: elem['new_data'],
          created_at: elem['created_at'],
        });
      }
      return data;
    },
  },
}
</script>
