<template>
  <div>
    <modal-update
      :update-modal-input-data="updateModalInputData"
      :primary-key-value="selectedId"
      :title="updateTitle"
      @onUpdate="updateList"
      updateModalUrl="/restaurantCredential/update"
      ref="modalUpdateComponent">
    </modal-update>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      table-title="Реквизиты заведений"
      deleteByIdUrl="/restaurantCredential/delete"
      deleteByIdMethod="post"
      changeItemWithLoaderMethod="post"
      changeItemWithLoaderUrl="/restaurantCredential/update"
      :addModalInputData="addModalInputData"
    >
      <template v-slot:base-table-actions="props">
        <div class="topping__btns fx col-12">
          <button class="btn-t btn--borderColor flex-center" @click="props.toggleShowModalAdd">Добавить</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <div @click="toggleShowModalUpdate(props.row)" style="cursor: pointer">
          <template v-if="props.column.field === 'restaurant'">
            {{ props.formattedRow[props.column.field]['title'] + ' (' + props.formattedRow[props.column.field]['id'] + ')' }}
          </template>
          <template v-else-if="props.column.field === 'tinkoff_shop_code'">
            <span :class="props.formattedRow[props.column.field] ? 'text-success' : 'text-danger'">{{ props.formattedRow[props.column.field] ? 'ДА' : 'НЕТ' }}</span>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import Modal from '../../../components/table/modal.vue';
import BaseAdminMixin from "../../../components/mixins/BaseAdminMixin.vue";
import Multiselect from "vue-multiselect";
import BaseTable from "../../../components/table/baseTable";
import ModalUpdate from "../../../components/table/modalUpdate.vue";

export default {
  name: "restaurantsList",
  components: {
    ModalUpdate,
    Multiselect,
    BaseAdminMixin,
    Modal,
    BaseTable
  },
  mixins: [
    BaseAdminMixin,
  ],
  data: function () {
    return {
      showModalAdd: false,
      showModalUpdate: false,
      restaurantId: null,
      selectedId: '',
      updateTitle: '',
      updateModalInputData: {
        items:{
          contract_number: {
            simpleInputType: 'text',
            upperSpanText: 'Номер договора*',
            selectedKey: 'contract_number',
          },
          contract_date: {
            simpleInputType: 'date',
            upperSpanText: 'Дата договора*',
            selectedKey: 'contract_date',
          },
          name: {
            simpleInputType: 'text',
            upperSpanText: 'Сокращенное наименование организации (ООО..., ИП...)*',
            selectedKey: 'name',
          },
          full_name: {
            simpleInputType: 'text',
            upperSpanText: 'Полное наименование организации*',
            selectedKey: 'full_name',
          },
          inn: {
            simpleInputType: 'number',
            upperSpanText: 'ИНН*',
            selectedKey: 'inn',
          },
          ogrn: {
            simpleInputType: 'number',
            upperSpanText: 'ОГРН*',
            selectedKey: 'ogrn',
          },
          kpp: {
            simpleInputType: 'text',
            upperSpanText: 'КПП (если нет - оставить пустым)',
            selectedKey: 'kpp',
          },
          phones: {
            simpleInputType: 'text',
            upperSpanText: 'Номер телефона*',
            selectedKey: 'phones',
          },
          email: {
            simpleInputType: 'text',
            upperSpanText: 'Email организации*',
            selectedKey: 'email',
          },
          site_url: {
            simpleInputType: 'text',
            upperSpanText: 'Сайт организации*',
            selectedKey: 'site_url',
          },
          billing_descriptor: {
            simpleInputType: 'text',
            upperSpanText: 'Название магазина в СМС на иностранном языке*',
            selectedKey: 'billing_descriptor',
          },
          address_zip: {
            simpleInputType: 'text',
            upperSpanText: 'ZIP адреса организации*',
            selectedKey: 'address_zip',
          },
          address_country_iso: {
            simpleInputType: 'text',
            upperSpanText: '3-ех значный ISO код страны адреса организации*',
            selectedKey: 'address_country_iso',
          },
          address_city: {
            simpleInputType: 'text',
            upperSpanText: 'Страна адреса организации*',
            selectedKey: 'address_city',
          },
          address_street: {
            simpleInputType: 'text',
            upperSpanText: 'Улица, дом адреса организации*',
            selectedKey: 'address_street',
          },
          ceo_first_name: {
            simpleInputType: 'text',
            upperSpanText: 'Имя руководителя организации*',
            selectedKey: 'ceo_first_name',
          },
          ceo_last_name: {
            simpleInputType: 'text',
            upperSpanText: 'Фамилия руководителя организации*',
            selectedKey: 'ceo_last_name',
          },
          ceo_middle_name: {
            simpleInputType: 'text',
            upperSpanText: 'Отчество руководителя организации*',
            selectedKey: 'ceo_middle_name',
          },
          ceo_birth_date: {
            simpleInputType: 'date',
            upperSpanText: 'Дата рождения руководителя организации*',
            selectedKey: 'ceo_birth_date',
          },
          ceo_phone: {
            simpleInputType: 'number',
            upperSpanText: 'Телефон руководителя организации*',
            selectedKey: 'ceo_phone',
          },
          ceo_country_iso: {
            simpleInputType: 'text',
            upperSpanText: 'ISO код страны гражданства руководителя организации*',
            selectedKey: 'ceo_country_iso',
          },
          bank_account: {
            simpleInputType: 'number',
            upperSpanText: 'Расчетный или казначейский счет организации*',
            selectedKey: 'bank_account',
          },
          bank_name: {
            simpleInputType: 'text',
            upperSpanText: 'Наименование банка организации*',
            selectedKey: 'bank_name',
          },
          bank_bik: {
            simpleInputType: 'number',
            upperSpanText: 'БИК*',
            selectedKey: 'bank_bik',
          },
          bank_details: {
            simpleInputType: 'text',
            upperSpanText: 'Назначение платежа*',
            selectedKey: 'bank_details',
          },
          bank_tax: {
            simpleInputType: 'number',
            upperSpanText: 'Отчисления в пользу маркетплейса, % от суммы операции*',
            selectedKey: 'bank_tax',
          },
        }
      },
      addModalInputData: {
        addModalTitle: 'Добавить',
        addModalUrl: '/restaurantCredential/create',
        addModalMethod: 'post',
        items:{
          restaurant_id: {
            multiple: false,
            allowEmpty: false,
            closeOnSelect: true,
            noGroupSelect: true,
            getDataLink: '/restaurant/getList',
            placeholder: 'Выберите заведение*',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            selectedKey: 'restaurant_id',
          },
          contract_number: {
            simpleInputType: 'text',
            upperSpanText: 'Номер договора*',
            placeholder: 'Номер договора*',
            selectedKey: 'contract_number',
          },
          contract_date: {
            simpleInputType: 'date',
            upperSpanText: 'Дата договора*',
            placeholder: 'Дата договора*',
            selectedKey: 'contract_date',
          },
          name: {
            simpleInputType: 'text',
            upperSpanText: 'Сокращенное наименование организации*',
            placeholder: 'Сокращенное наименование организации*',
            selectedKey: 'name',
          },
          full_name: {
            simpleInputType: 'text',
            upperSpanText: 'Полное наименование организации*',
            placeholder: 'Полное наименование организации*',
            selectedKey: 'full_name',
          },
          inn: {
            simpleInputType: 'number',
            upperSpanText: 'ИНН*',
            placeholder: 'ИНН*',
            selectedKey: 'inn',
          },
          ogrn: {
            simpleInputType: 'number',
            upperSpanText: 'ОГРН*',
            placeholder: 'ОГРН*',
            selectedKey: 'ogrn',
          },
          kpp: {
            simpleInputType: 'text',
            upperSpanText: 'КПП (если нет - оставить пустым)',
            placeholder: 'КПП (если нет - оставить пустым)',
            selectedKey: 'kpp',
          },
          phones: {
            simpleInputType: 'number',
            upperSpanText: 'Номер телефона организации*',
            placeholder: 'Номер телефона организации*',
            selectedKey: 'phones',
          },
          email: {
            simpleInputType: 'text',
            upperSpanText: 'Email организации*',
            placeholder: 'Email организации*',
            selectedKey: 'email',
          },
          site_url: {
            simpleInputType: 'text',
            upperSpanText: 'Сайт организации*',
            placeholder: 'Сайт организации*',
            selectedKey: 'site_url',
          },
          billing_descriptor: {
            simpleInputType: 'text',
            upperSpanText: 'Название магазина в СМС на иностранном языке*',
            placeholder: 'Название магазина в СМС на иностранном языке*',
            selectedKey: 'billing_descriptor',
          },
          address_zip: {
            simpleInputType: 'text',
            upperSpanText: 'ZIP адреса организации*',
            placeholder: 'ZIP адреса организации*',
            selectedKey: 'address_zip',
          },
          address_country_iso: {
            simpleInputType: 'text',
            upperSpanText: '3-ех значный ISO код страны адреса организации*',
            placeholder: '3-ех значный ISO код страны адреса организации*',
            selectedKey: 'address_country_iso',
          },
          address_city: {
            simpleInputType: 'text',
            upperSpanText: 'Страна адреса организации*',
            placeholder: 'Страна адреса организации*',
            selectedKey: 'address_city',
          },
          address_street: {
            simpleInputType: 'text',
            upperSpanText: 'Улица, дом адреса организации*',
            placeholder: 'Улица, дом адреса организации*',
            selectedKey: 'address_street',
          },
          ceo_first_name: {
            simpleInputType: 'text',
            upperSpanText: 'Имя руководителя организации*',
            placeholder: 'Имя руководителя организации*',
            selectedKey: 'ceo_first_name',
          },
          ceo_last_name: {
            simpleInputType: 'text',
            upperSpanText: 'Фамилия руководителя организации*',
            placeholder: 'Фамилия руководителя организации*',
            selectedKey: 'ceo_last_name',
          },
          ceo_middle_name: {
            simpleInputType: 'text',
            upperSpanText: 'Отчество руководителя организации*',
            placeholder: 'Отчество руководителя организации*',
            selectedKey: 'ceo_middle_name',
          },
          ceo_birth_date: {
            simpleInputType: 'date',
            upperSpanText: 'Дата рождения руководителя организации*',
            placeholder: 'Дата рождения руководителя организации*',
            selectedKey: 'ceo_birth_date',
          },
          ceo_phone: {
            simpleInputType: 'number',
            upperSpanText: 'Телефон руководителя организации*',
            placeholder: 'Телефон руководителя организации*',
            selectedKey: 'ceo_phone',
          },
          ceo_country_iso: {
            simpleInputType: 'text',
            upperSpanText: 'ISO код страны гражданства руководителя организации*',
            placeholder: 'ISO код страны гражданства руководителя организации*',
            selectedKey: 'ceo_country_iso',
          },
          bank_account: {
            simpleInputType: 'number',
            upperSpanText: 'Расчетный или казначейский счет организации',
            placeholder: 'Расчетный или казначейский счет организации*',
            selectedKey: 'bank_account',
          },
          bank_name: {
            simpleInputType: 'text',
            upperSpanText: 'Наименование банка организации*',
            placeholder: 'Наименование банка организации*',
            selectedKey: 'bank_name',
          },
          bank_bik: {
            simpleInputType: 'number',
            upperSpanText: 'БИК*',
            placeholder: 'БИК*',
            selectedKey: 'bank_bik',
          },
          bank_details: {
            simpleInputType: 'text',
            upperSpanText: 'Назначение платежа*',
            placeholder: 'Назначение платежа*',
            selectedKey: 'bank_details',
          },
          bank_tax: {
            simpleInputType: 'number',
            upperSpanText: 'Отчисления в пользу маркетплейса, % от суммы операции*',
            placeholder: 'Отчисления в пользу маркетплейса, % от суммы операции*',
            selectedKey: 'bank_tax',
          },
        }
      },
      wrapperFiltersData: {
        title: "Реквизиты заведений",
        filtersUrl: "/restaurantCredential/getList",
        filtersUrlMethod: "get",
        startWithRequest: true,
        items: {
          restaurants: {
            enable: true,
            multiple: true,
            getDataLink: '/restaurant/getList',
            placeholder: 'Рестораны',
            specifyKeyForLabel: 'title',
            viewLabel: 'titleWithId',
            column: 1
          },
        },
      },
      columns: [
        {
          label: 'Ресторан',
          field: 'restaurant',
          type: 'number',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Название организации',
          field: 'name',
          width: '20%',
          sortable: true,
        },
        {
          label: 'Полное название организации',
          field: 'full_name',
          width: '30%',
          sortable: true,
        },
        {
          label: 'ИНН',
          field: 'inn',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Зарегистрирован в T-Bank',
          field: 'tinkoff_shop_code',
          sortable: true,
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push(elem);
      }
      return data;
    },
    toggleShowModalUpdate(elem) {
      if (elem) {
        this.selectedId = elem.id
        this.updateModalInputData.items.contract_number.defaultValue = elem.contract_number
        this.updateModalInputData.items.contract_date.defaultValue = elem.contract_date
        this.updateModalInputData.items.name.defaultValue = elem.name
        this.updateModalInputData.items.inn.defaultValue = elem.inn
        this.updateModalInputData.items.phones.defaultValue = elem['phones'] ? elem['phones'].join(',') : null
        this.updateModalInputData.items.billing_descriptor.defaultValue = elem.billing_descriptor
        this.updateModalInputData.items.full_name.defaultValue = elem.full_name
        this.updateModalInputData.items.ogrn.defaultValue = elem.ogrn
        this.updateModalInputData.items.address_zip.defaultValue = elem.address_zip
        this.updateModalInputData.items.address_country_iso.defaultValue = elem.address_country_iso
        this.updateModalInputData.items.address_city.defaultValue = elem.address_city
        this.updateModalInputData.items.address_street.defaultValue = elem.address_street
        this.updateModalInputData.items.email.defaultValue = elem.email
        this.updateModalInputData.items.ceo_first_name.defaultValue = elem.ceo_first_name
        this.updateModalInputData.items.ceo_last_name.defaultValue = elem.ceo_last_name
        this.updateModalInputData.items.ceo_middle_name.defaultValue = elem.ceo_middle_name
        this.updateModalInputData.items.ceo_birth_date.defaultValue = elem.ceo_birth_date
        this.updateModalInputData.items.ceo_phone.defaultValue = elem.ceo_phone
        this.updateModalInputData.items.ceo_country_iso.defaultValue = elem.ceo_country_iso
        this.updateModalInputData.items.site_url.defaultValue = elem.site_url
        this.updateModalInputData.items.bank_account.defaultValue = elem.bank_account
        this.updateModalInputData.items.bank_name.defaultValue = elem.bank_name
        this.updateModalInputData.items.bank_bik.defaultValue = elem.bank_bik
        this.updateModalInputData.items.bank_details.defaultValue = elem.bank_details
        this.updateModalInputData.items.bank_tax.defaultValue = elem.bank_tax
        this.updateTitle = 'Реквизиты заведения ' + elem.restaurant.title + ' (' + elem.restaurant_id + ')'
      }

      if (this.$refs.modalUpdateComponent) {
        this.$refs.modalUpdateComponent.toggleShowModalUpdate();
      }
    },
    updateList() {
      this.$root.$emit('update-list')
    }
  },
}
</script>
